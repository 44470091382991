import ContactForm from "../modules/ContactForm";

const Contact = () => {
    return <>
        <link rel="stylesheet" href="css/Contact.module.css" />
        <div className="mkd-content">
            {/*SLIDER START*/}
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <iframe
                            loading="lazy"
                            src="https://maps.google.com/maps?q=Newry%20Properties%20Private%20Limited%20Newry%20Grandeur%2C%20No.%2019%2C%20B%20Block%2C%202nd%20Avenue%2C%2C%20Annanagar%20East%2C%20Chennai%2C%20Tamil%20Nadu%20600102&t=m&z=14&output=embed&iwloc=near"
                            title="Newry Properties Private Limited Newry Grandeur, No. 19, B Block, 2nd Avenue,, Annanagar East, Chennai, Tamil Nadu 600102"
                            aria-label="Newry Properties Private Limited Newry Grandeur, No. 19, B Block, 2nd Avenue,, Annanagar East, Chennai, Tamil Nadu 600102"
                        />
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="vc_empty_space" style={{ height: 50 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            {/*OUR PHILOSOPHY*/}
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516716512293">

                                    </div>
                                </div>
                            </div>
                            {/*FUTURE*/}
                            <section className="wpb-content-wrapper location-advantages">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516007647796">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-8773"
                                                                data-1280-1600="0 0 20px 0"
                                                                data-1024-1280="0 0 20px 0"
                                                                data-768-1024="0 0 20px 0"
                                                                data-680-768="0 0 20px 0"
                                                                data-680="0px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                        style={{ padding: "0 0 20px 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner road">
                                                                                <h2 className="mkd-st-title">CONTACT US</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div
                                className="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768"
                                style={{ backgroundColor: "#ebebeb" }}
                            >
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                        <div
                                                                            className="mkd-eh-item    mkd-element-from-bottom"
                                                                            data-item-class="mkd-eh-custom-9421"
                                                                            data-animation="mkd-element-from-bottom"
                                                                            data-1280-1600="10px 0 0 8px"
                                                                            data-1024-1280="10px 0 0 8px"
                                                                            data-768-1024="10px 0 0 0"
                                                                            data-680-768="10px 0 0px 0"
                                                                            data-680="10px 0 0 0"
                                                                        >
                                                                            <div className="mkd-eh-item-inner">
                                                                                <div className="mkd-eh-item-content mkd-eh-custom-9421">
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 50 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="wpb_text_column wpb_content_element  vc_custom_1516695756662">
                                                                                        <div className="wpb_wrapper">
                                                                                            <h4 style={{ paddingBottom: 6 }}>
                                                                                                Visit Us
                                                                                            </h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 23 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                        <div className="wpb_wrapper">
                                                                                            <p>
                                                                                                Newry Properties Pvt Ltd, <br />
                                                                                                Newry Grandeur, No. 19, B Block,{" "}
                                                                                                <br />
                                                                                                2nd Avenue, Annanagar East, <br />
                                                                                                Chennai, Tamil Nadu-600102
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 28 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                        <div className="wpb_wrapper">
                                                                                            <p>
                                                                                                {" "}
                                                                                                info@newry.co.in
                                                                                                <br />
                                                                                                +91-44-4343-9494
                                                                                                <br />
                                                                                                +91-999 4144 999
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 28 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                        <div className="wpb_wrapper">
                                                                                            <p></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-6 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                        <div
                                                                            className="mkd-eh-item    mkd-element-from-bottom"
                                                                            data-item-class="mkd-eh-custom-1619"
                                                                            data-animation="mkd-element-from-bottom"
                                                                            data-1280-1600="0 0 0 0"
                                                                            data-1024-1280="0 0 0 0"
                                                                            data-768-1024="0 0 0 0"
                                                                            data-680-768="0 0 0 0"
                                                                            data-680="0 0 0 0"
                                                                        >
                                                                            <div className="mkd-eh-item-inner">
                                                                                <div className="mkd-eh-item-content mkd-eh-custom-1619">
                                                                                    <div className="mkd-single-image-holder  mkd-image-behavior-lightbox   ">
                                                                                        <div className="mkd-si-inner">
                                                                                            <a
                                                                                                itemProp="image"
                                                                                                href="assets/newry.jpg"
                                                                                                data-rel="prettyPhoto[si_pretty_photo]"
                                                                                                title="a"
                                                                                            >
                                                                                                <img
                                                                                                    width={600}
                                                                                                    height={475}
                                                                                                    src="assets/newry.jpg"
                                                                                                    className="attachment-full size-full"
                                                                                                    alt="a"
                                                                                                    loading="lazy"
                                                                                                />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                        <div
                                                                            className="mkd-eh-item    mkd-element-from-bottom"
                                                                            data-item-class="mkd-eh-custom-3912"
                                                                            data-animation="mkd-element-from-bottom"
                                                                            data-1280-1600="0 0 0 0"
                                                                            data-1024-1280="0 0 0 0"
                                                                            data-768-1024="0 0 0 0"
                                                                            data-680-768="0 0 0 0"
                                                                            data-680="0 0 0 0"
                                                                        >
                                                                            <div className="mkd-eh-item-inner">
                                                                                <div className="mkd-eh-item-content mkd-eh-custom-3912">
                                                                                    <div
                                                                                        role="form"
                                                                                        className="wpcf7"
                                                                                        id="wpcf7-f1911-p1871-o2"
                                                                                        lang="en-US"
                                                                                        dir="ltr"
                                                                                    >
                                                                                        <div className="screen-reader-response">
                                                                                            <p
                                                                                                role="status"
                                                                                                aria-live="polite"
                                                                                                aria-atomic="true"
                                                                                            ></p>
                                                                                            <ul />
                                                                                        </div>
                                                                                        <div
                                                                                            className="vc_empty_space"
                                                                                            style={{ height: 28 }}
                                                                                        >
                                                                                            <span className="vc_empty_space_inner" />
                                                                                        </div>
                                                                                        <ContactForm />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Contact;