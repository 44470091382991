import React from "react";
import CSRIcons from "../modules/CSRIcons";

const Csr = () => {
    return <>
     <link rel="stylesheet" href="css/CSR.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/freepik/12.jpg" />
                            <source media="(max-width: 480px)" srcSet="assets/freepik/13.jpg" />
                            <img
                                className="d-block w-100"
                                src="assets/freepik/13.jpg"
                                alt="Banner"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            <div className="vc_empty_space" style={{ height: 40 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <p style={{ textAlign: "justify"}}>
                                                            At Newry Properties, our enduring commitment to social
                                                            responsibility is intrinsically tied to our mission of
                                                            providing high-quality and affordable homes for over
                                                            25 years. We extend this commitment not only to our
                                                            homeowners but to society at large. It involves
                                                            enriching lives, fostering sustainable communities,
                                                            and actively participating in a brighter future,
                                                            harmonious for all.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{ height: 30 }}>
                                    <span className="vc_empty_space_inner" />
                                </div>
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div className="mkd-eh-item    ">
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner">
                                                                                    <h2 className="mkd-st-title">
                                                                                        EDUCATING COMMUNITIES
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{ height: 32 }}>
                                    <span className="vc_empty_space_inner" />
                                </div>
                                <div className="mkd-full-width">
                                    <div className="mkd-full-width-inner">
                                        <div className="mkd-grid-row">
                                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                                <section className="wpb-content-wrapper">
                                                    <div className="mkd-row-grid-section-wrapper ">
                                                        <div className="mkd-row-grid-section">
                                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921277402">
                                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-portfolio-list-holder mkd-pl-gallery mkd-huge-space mkd-pl-one-column mkd-pl-floating-portfolio-item  mkd-pl-pag-no-pagination ">
                                                                                <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/aboutus/SHRISHTI-VIDYASHARAM.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h3
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                          
                                                                                                        >
                                                                                                            Shrishti Vidyasharam
                                                                                                        </h3>
                                                                                                        <div
                                                                                                            className="mkd-pli-category-holder"
                                                                                                            style={{ textAlign: "right" }}
                                                                                                        >
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    2509 Students
                                                                                                                </a>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <p style={{ textAlign: "justify"}}
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            We consistently try and raise
                                                                                                            the bar in academics and
                                                                                                            extracurricular activities.
                                                                                                            Our management's uncompromised
                                                                                                            pursuit of excellence, along
                                                                                                            with the dedication of our
                                                                                                            teachers and ensures a
                                                                                                            holistic development for every
                                                                                                            child in our care.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/aboutus/SHRISHTI-MATRIC.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <h3
                                                                                                            itemProp="name"
                                                                                                            className="mkd-pli-title entry-title"
                                                                                                            style={{
                                                                                                                textAlign: "justify"
                                                                                                            }}
                                                                                                        >
                                                                                                            Shrishti Matric Hr. Sec.
                                                                                                            School
                                                                                                        </h3>
                                                                                                        <div
                                                                                                            className="mkd-pli-category-holder"
                                                                                                            style={{ textAlign: "right" }}
                                                                                                        >
                                                                                                            <p>
                                                                                                                <a
                                                                                                                    itemProp="url"
                                                                                                                    className="mkd-pli-category"
                                                                                                                >
                                                                                                                    918 Students
                                                                                                                </a>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <p style={{ textAlign: "justify"}}
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                           
                                                                                                        >
                                                                                                            With a steadfast commitment to
                                                                                                            academic and extracurricular
                                                                                                            excellence our institution has
                                                                                                            become integral parts of
                                                                                                            Vellore's educational
                                                                                                            landscape. Our management,
                                                                                                            dedicated teachers, and active
                                                                                                            parents' council form the
                                                                                                            backbone of the Shrishti Group
                                                                                                            of Institutions.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner">
                                                                                    <h2 className="mkd-st-title educate">
                                                                                        EDUCATING INDIGENT COMMUNITIES
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516876827609 vc_column-gap-35">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="vc_empty_space" style={{ height: 32 }}>
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                        <div className="mkd-full-width">
                                                            <div className="mkd-full-width-inner">
                                                                <div className="mkd-grid-row">
                                                                    <div className="mkd-page-content-holder mkd-grid-col-12">
                                                                        <section className="wpb-content-wrapper">
                                                                            <div className="mkd-row-grid-section-wrapper ">
                                                                                <div className="mkd-row-grid-section">
                                                                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921277402">
                                                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                                            <div className="vc_column-inner">
                                                                                                <div className="wpb_wrapper">
                                                                                                    <div className="mkd-portfolio-list-holder mkd-pl-gallery mkd-huge-space mkd-pl-one-column mkd-pl-floating-portfolio-item  mkd-pl-pag-no-pagination ">
                                                                                                        <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                                                            <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                                                <div className="mkd-pl-item-inner">
                                                                                                                    <div className="mkd-pli-image-holder">
                                                                                                                        <div className="mkd-pli-image">
                                                                                                                            <img
                                                                                                                                src="assets/aboutus/school1.jpg"
                                                                                                                                className="attachment-full size-full wp-post-image"
                                                                                                                                alt="a"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="mkd-pli-image-drop-shadow"
                                                                                                                            style={{
                                                                                                                                backgroundColor:
                                                                                                                                    "#f2e9e9"
                                                                                                                            }}
                                                                                                                        ></div>
                                                                                                                    </div>
                                                                                                                    <div className="mkd-pli-text-holder">
                                                                                                                        <div className="mkd-pli-text-wrapper">
                                                                                                                            <div className="mkd-pli-text">
                                                                                                                                <h3
                                                                                                                                    itemProp="name"
                                                                                                                                    className="mkd-pli-title entry-title"
                                                                                                                                 
                                                                                                                                >
                                                                                                                                    Makhija's Shrishti
                                                                                                                                    Padasala
                                                                                                                                </h3>
                                                                                                                                <div
                                                                                                                                    className="mkd-pli-category-holder"
                                                                                                                                    style={{
                                                                                                                                        textAlign:
                                                                                                                                            "right"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <p>
                                                                                                                                        <a
                                                                                                                                            itemProp="url"
                                                                                                                                            className="mkd-pli-category"
                                                                                                                                        >
                                                                                                                                            418 Students
                                                                                                                                        </a>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <p style={{ textAlign: "justify"}}
                                                                                                                                    itemProp="description"
                                                                                                                                    className="mkd-pli-excerpt"
                                                                                                                                >
                                                                                                                                    Founded in memory
                                                                                                                                    of Shri Manu
                                                                                                                                    Makhija's vision,
                                                                                                                                    Shri Manu Makhija
                                                                                                                                    Memorial Shrishti
                                                                                                                                    Padasala offers
                                                                                                                                    free education,
                                                                                                                                    including English
                                                                                                                                    medium
                                                                                                                                    instruction,
                                                                                                                                    uniforms, and
                                                                                                                                    meals, to
                                                                                                                                    underprivileged
                                                                                                                                    children from
                                                                                                                                    thirty villages
                                                                                                                                    near Katpadi.
                                                                                                                                    Currently we are
                                                                                                                                    serving 418
                                                                                                                                    students with the
                                                                                                                                    school aims to
                                                                                                                                    expand to
                                                                                                                                    accommodate over
                                                                                                                                    400 students up to
                                                                                                                                    Std. V.
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </article>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{ height: 40 }}>
                                    <span className="vc_empty_space_inner" />
                                </div>
                                <div
                                    className="mkd-row-grid-section-wrapper mkd-parallax-row-holder mkd-content-aligment-left"
                                    data-parallax-bg-image="assets/freepik/14.jpg"
                                    data-parallax-bg-speed={1}
                                    data-parallax-bg-height={400}
                                >
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516718150958">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-1384"
                                                                data-1280-1600="100px 0 0 0 "
                                                                data-1024-1280="100px 0 0 0 "
                                                                data-768-1024="100px 0 164px 0"
                                                                data-680-768="100px 0 164px 0"
                                                                data-680="100px 0"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-1384"
                                                                        style={{ padding: "100px 0 0 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{ height: 40 }}>
                                    <span className="vc_empty_space_inner" />
                                </div>
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner">
                                                                                    <h2 className="mkd-st-title">
                                                                                        RESPONSIBLE IN EVERY WAY
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="mkd-full-width">
                                    <div className="mkd-full-width-inner">
                                        <div className="mkd-grid-row">
                                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                                <section className="wpb-content-wrapper location-advantages">
                                                    <div className="mkd-row-grid-section-wrapper ">
                                                        <div className="mkd-row-grid-section">
                                                            <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <CSRIcons
                                                                                id="icon1"
                                                                                defaultSrc="assets/icons/global.png"
                                                                                hoverSrc="assets/aboutus/global-animated.svg"
                                                                                titleText="Global Reach, Local Impact"
                                                                                listItemText="Innovate in design to create living spaces that resonate with evolving customer needs."
                                                                            /> 
                                                                            </div>
                                                                            <div
                                                                                className="vc_empty_space"
                                                                                style={{ height: 38 }}
                                                                            >
                                                                                <span className="vc_empty_space_inner" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                            <CSRIcons
                                                                                id="icon2"
                                                                                defaultSrc="assets/icons/quality.png"
                                                                                hoverSrc="assets/aboutus/quality-animated.svg"
                                                                                titleText={<>
                                                                                Quality <br /> Assurance
                                                                                </>}
                                                                                listItemText="Champion environmental stewardship through sustainable practices and technology."
                                                                            />  
                                                                            </div>
                                                                            <div
                                                                                className="vc_empty_space"
                                                                                style={{ height: 38 }}
                                                                            >
                                                                                <span className="vc_empty_space_inner" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                                                <CSRIcons
                                                                                    id="icon3"
                                                                                    defaultSrc="assets/icons/environment.png"
                                                                                    hoverSrc="assets/aboutus/environment-animated.svg"
                                                                                    titleText={<>
                                                                                    Environmental <br /> Responsibility
                                                                                    </>}
                                                                                    listItemText="Elevate community engagement, enriching lives beyond our developments."
                                                                                />   
                                                                            </div>
                                                                            <div
                                                                                className="vc_empty_space"
                                                                                style={{ height: 38 }}
                                                                            >
                                                                                <span className="vc_empty_space_inner" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Csr;