const Completed = () => {
  return <>
    <link rel="stylesheet" href="css/Completed.module.css" />
    <div className="mkd-content">
      {/*SLIDER START*/}
      <div className="mkd-ps-image-holder">
        <div className="mkd-ps-image mkd-owl-slider">
          <div className="mkd-ps-image">
          <picture>
              <source media="(min-width: 481px)" srcSet="assets/arista1.webp" />
              <source
                media="(max-width: 480px)"
                srcSet="assets/arista1-mobile.webp"
              />
              <img
                className="d-block w-100"
                src="assets/arista1-mobile.webp"
                alt="Banner"
              />
            </picture>
          </div>
        </div>
      </div>
      {/*SLIDER END*/}
      <div className="vc_empty_space" style={{ height: 40 }}>
        <span className="vc_empty_space_inner" />
      </div>
      {/*COMPLETED START*/}
      <div className="mkd-content">
        <div className="mkd-content-inner">
          <section className="wpb-content-wrapper location-advantages">
            <div className="mkd-row-grid-section-wrapper ">
              <div className="mkd-row-grid-section">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1516007647796">
                      <div className="wpb_wrapper">
                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                          <div
                            className="mkd-eh-item    "
                            data-item-class="mkd-eh-custom-8773"
                            data-1280-1600="0 0 20px 0"
                            data-1024-1280="0 0 20px 0"
                            data-768-1024="0 0 20px 0"
                            data-680-768="0 0 20px 0"
                            data-680="0px"
                          >
                            <div className="mkd-eh-item-inner">
                              <div
                                className="mkd-eh-item-content mkd-eh-custom-8773"
                                style={{ padding: "0 0 20px 0" }}
                              >
                                <div className="mkd-section-title-holder  ">
                                  <div className="mkd-st-inner road">
                                    <h2 className="mkd-st-title">
                                      Completed Projects
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="mkd-full-width">
            <div className="mkd-full-width-inner">
              <div className="mkd-grid-row">
                <div className="mkd-page-content-holder mkd-grid-col-12">
                  <section className="wpb-content-wrapper">
                    <div className="mkd-row-grid-section-wrapper ">
                      <div className="mkd-row-grid-section">
                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921876626">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="mkd-portfolio-list-holder mkd-pl-gallery mkd-normal-space mkd-pl-three-columns mkd-pl-standard-shader    mkd-pl-pag-no-pagination     ">
                                  <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                  <article className="mkd-pl-item mkd-item-space  post-1636 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization flipper-container">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/arista-project.webp"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className=" mkd-pli-title entry-title"
                                              >
                                                Newry Arista{" "}
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Kolathur
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                Arista is a Sanskrit word that means perfect, complete, imperishable, secure, safe and happy. These are the qualities that define Arista, a premium residential project by Newry in Kolathur. Arista features spacious and elegant 2.5 BHK apartments, with modern amenities and security systems.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1636 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization flipper-container">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/aura.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className=" mkd-pli-title entry-title"
                                              >
                                                Newry Aura{" "}
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Thirumullaivoyal
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  With Aura give a refreshing start
                                                  to life with our beautiful homes.
                                                  Crafted with flair and built to
                                                  perfection, these homes offer
                                                  comfort and quality. Choose from
                                                  smartly designed 2, 2.5, and 3
                                                  BHKs that effortlessly blend space
                                                  and style. Located in
                                                  Thirumullaivoyul, our community
                                                  comprises 120 units.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1635 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/vishvam.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                Newry Vishvam
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Adyar
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  A luxury home perfectly suited for
                                                  your lifestyle. This inspiring
                                                  world of fine living features just
                                                  six 3 BHK apartments, meticulously
                                                  designed across stilt plus 3
                                                  floors on a land area spanning
                                                  4250 square feet. Located in
                                                  Adyar, Vishvam is truly your
                                                  world, your home sweet home.
                                                  Spacious, monumental, and
                                                  exquisite in every aspect.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1634 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/mahonnathi.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                Newry Mahonnathi
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Adyar
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  A master-planned luxury 3BHK home
                                                  for just 6 privileged families.
                                                  Developed on 3975 square feet of
                                                  land in Indira Nagar, Adyar, this
                                                  inspiring home, built across stilt
                                                  plus 3 floors, offers everything
                                                  you need to create your own
                                                  paradise. With only 2 apartments
                                                  per floor, these homes generously
                                                  spread across 1451 sq.ft. and 1455
                                                  sq.ft.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    {/*<article*/}
                                    {/*    class="mkd-pl-item mkd-item-space  post-1633 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">*/}
                                    {/*    <div class="mkd-pl-item-inner">*/}
                                    {/*        <div class="mkd-pli-image">*/}
                                    {/*             <img width="550" height="550"*/}
                                    {/*                src="https://via.placeholder.com/550x550"*/}
                                    {/*                class="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"*/}
                                    {/*                alt="a" />*/}
                                    {/*        </div>*/}
                                    {/*        <div class="mkd-pli-text-holder">*/}
                                    {/*            <div class="mkd-pli-text-wrapper">*/}
                                    {/*                <div class="mkd-pli-text">*/}
                                    {/*                    <h4 itemprop="name"*/}
                                    {/*                        class="mkd-pli-title entry-title">*/}
                                    {/*                        Newry Aprita*/}
                                    {/*                    </h4>*/}
                                    {/*                    <div*/}
                                    {/*                        class="mkd-pli-category-holder">*/}
                                    {/*                        <p>*/}
                                    {/*                            <a itemprop="url"*/}
                                    {/*                                class="mkd-pli-category"*/}
                                    {/*                                >Kottivakkam</a>*/}
                                    {/*                        </p>*/}
                                    {/*                        <p>Newry Aprita located at Kottivakkam at Gandhi Road between ECR and OMR is meticulously planned to accommodate 16 luxury inbuilt lifestyle apartments on 9717 sq.ft of land. Built on Stilt + 3 Floors, the luxury apartments of 2BHK & 3 BHK are space efficiently designed with an eye for minute details. </p>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div> */}
                                    {/*    </div>*/}
                                    {/*</article>*/}
                                    <article className="mkd-pl-item mkd-item-space  post-1632 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/celio.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                Newry Celio
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Poonamallee
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  A unique design that epitomizes a
                                                  premium way of life and elegant
                                                  living, located in Paruthipattu,
                                                  Chennai. Nestled within a vast
                                                  community of exquisite residences,
                                                  Newry Celio elevates the modern
                                                  way of life. These Chennai
                                                  residential flats offer a
                                                  refreshing living experience,
                                                  inspiring you to make the most of
                                                  every moment.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/celestial.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                NEWRY CELESTIAL
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Paruthipattu
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  Welcome to Newry Celestial, where
                                                  comfort is our top priority.
                                                  Designed and built with your needs
                                                  in mind, our apartments offer
                                                  prime living conditions. With a
                                                  12-month defect liability period
                                                  from possession, maintaining your
                                                  space is easy. Located in
                                                  Paruthipattu, Chennai, our 108
                                                  units promise harmonious communal
                                                  living.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/arcade.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                NEWRY ARCADE
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Panaiyur
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  Witness a revolutionary shopping
                                                  complex in Panaiyur, Chennai,
                                                  redefining design with innovative
                                                  approaches. Inspired by festive
                                                  gatherings, our roof mimics a
                                                  'pandal', creating a unique
                                                  architectural marvel. Featuring 12
                                                  units, experience seamless
                                                  transitions from public to private
                                                  spaces in this transformative
                                                  project.{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/park-tower.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                NEWRY PARK TOWER
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Anna Nagar
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  Welcome to Newry Park Towers, the
                                                  epitome of premium multi-storeyed
                                                  residential living in Anna Nagar
                                                  West. Enjoy proximity to esteemed
                                                  schools, bustling commercial hubs,
                                                  corporate offices, and top-notch
                                                  multi-specialty hospitals. Embrace
                                                  the essence of city life in this
                                                  contemporary abode, offering 162
                                                  units across 2 buildings.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/shanmitha.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                NEWRY SHANMITHA
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Pallikaranai
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  Welcome to Newry Shanmita,
                                                  strategically situated near the
                                                  bustling IT corridor and vibrant
                                                  shopping districts, offering you
                                                  unparalleled convenience. Just 15
                                                  minutes from Velachery, the heart
                                                  of Chennai's excitement, enjoy
                                                  easy access to IT firms,
                                                  entertainment venues, and dining
                                                  options, saving you valuable time.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    <article className="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">
                                      <div className="mkd-pl-item-inner flipper">
                                        <div className="mkd-pli-image flipper-front">
                                          <img
                                            width={550}
                                            height={550}
                                            src="assets/completed/spring.jpg"
                                            className="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"
                                            alt="a"
                                          />
                                        </div>
                                        <div className="mkd-pli-text-holder flipper-back">
                                          <div className="mkd-pli-text-wrapper">
                                            <div className="mkd-pli-text">
                                              <h4
                                                itemProp="name"
                                                className="mkd-pli-title entry-title"
                                              >
                                                NEWRY SPRING
                                              </h4>
                                              <div className="mkd-pli-category-holder">
                                                <p>
                                                  <a
                                                    itemProp="url"
                                                    className="mkd-pli-category"
                                                  >
                                                    Anna Nagar
                                                  </a>
                                                </p>
                                                <p style={{ textAlign: "justify"}}>
                                                  Introducing Spring Residences by
                                                  Newry, an exquisite collection of
                                                  10 premium homes nestled in the
                                                  prestigious I Block of Anna Nagar
                                                  East. Crafted to perfection, this
                                                  enclave reflects your passion for
                                                  the finer things in life. With
                                                  meticulous design and a coveted
                                                  location, Newry Spring Residences
                                                  offers the home you've always
                                                  dreamed of.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                    {/*<article*/}
                                    {/*    class="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">*/}
                                    {/*    <div class="mkd-pl-item-inner">*/}
                                    {/*        <div class="mkd-pli-image">*/}
                                    {/*             <img width="550" height="550"*/}
                                    {/*                src="https://via.placeholder.com/550x550"*/}
                                    {/*                class="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"*/}
                                    {/*                alt="a" />*/}
                                    {/*        </div>*/}
                                    {/*        <div class="mkd-pli-text-holder">*/}
                                    {/*            <div class="mkd-pli-text-wrapper">*/}
                                    {/*                <div class="mkd-pli-text">*/}
                                    {/*                    <h4 itemprop="name"*/}
                                    {/*                        class="mkd-pli-title entry-title">*/}
                                    {/*                        NEWRY DAFFODILS*/}
                                    {/*                    </h4>*/}
                                    {/*                    <div*/}
                                    {/*                        class="mkd-pli-category-holder">*/}
                                    {/*                        <p>*/}
                                    {/*                            <a itemprop="url"*/}
                                    {/*                                class="mkd-pli-category"*/}
                                    {/*                                >Mylapore</a>*/}
                                    {/*                        </p>*/}
                                    {/*                        <p>A residential project spread over 0.11 Acres, it offers ample amount of facilities for residents. The project was launched in February 2014. It offers Ready to Move units. Popular configurations include 2 BHK, 3 BHK units. As per the area plan, units are in the size range of 1336.0 - 1784.0 sq.ft.. There are 6 units in Newry Daffodils. Overall, there is 1 building. The possession date of Newry Daffodils is Jul, 2015. The address of Newry Daffodils is Thiru.VI.KA 3rd Street, Royapettah High Road, Mylapore, Chennai.</p>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div> */}
                                    {/*    </div>*/}
                                    {/*</article>*/}
                                    {/*<article*/}
                                    {/*    class="mkd-pl-item mkd-item-space  post-1631 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-visualization">*/}
                                    {/*    <div class="mkd-pl-item-inner">*/}
                                    {/*        <div class="mkd-pli-image">*/}
                                    {/*             <img width="550" height="550"*/}
                                    {/*                src="https://via.placeholder.com/550x550"*/}
                                    {/*                class="attachment-entre_mikado_square size-entre_mikado_square wp-post-image"*/}
                                    {/*                alt="a" />*/}
                                    {/*        </div>*/}
                                    {/*        <div class="mkd-pli-text-holder">*/}
                                    {/*            <div class="mkd-pli-text-wrapper">*/}
                                    {/*                <div class="mkd-pli-text">*/}
                                    {/*                    <h4 itemprop="name"*/}
                                    {/*                        class="mkd-pli-title entry-title">*/}
                                    {/*                        Newry Mayfair*/}
                                    {/*                    </h4>*/}
                                    {/*                    <div*/}
                                    {/*                        class="mkd-pli-category-holder">*/}
                                    {/*                        <p>*/}
                                    {/*                            <a itemprop="url"*/}
                                    {/*                                class="mkd-pli-category"*/}
                                    {/*                                >Perungudi</a>*/}
                                    {/*                        </p>*/}
                                    {/*                        <p>This project is a perfect combination of comfort and style, specifically designed to suit your requirements and conveniences. This housing society is now ready to be called home as families have started moving in. Check out some of the features of Newry Mayfair housing society: </p>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div> */}
                                    {/*    </div>*/}
                                    {/*</article>*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*COMPLETED END*/}
    </div>

  </>;
};

export default Completed;