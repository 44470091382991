import React, { useRef, useState } from 'react';
import ProjectLocationIcons from "../modules/ProjectLocationIcons";
import ProjectAmenity from "../modules/ProjectAmenity";
import EnquiryForm from "../modules/EnquiryForm";

const Adora = () => {
  const formRef = useRef(null);
  const [clickedButton, setClickedButton] = useState('');

  const handleButtonClick = (buttonType) => {
    setClickedButton(buttonType);
    //   formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return <>
    <link rel="stylesheet" href="css/Adora.module.css" />
    <div className="mkd-content">
      <a id="mkd-back-to-top" href="#">
        <span className="mkd-icon-stack">
          <span aria-hidden="true" className="mkd-icon-font-elegant arrow_up  " />
        </span>
      </a>
      <div className="mkd-content-inner">
        <div className="mkd-container">
          <div className="mkd-container clearfix">
            <div className="mkd-portfolio-single-holder mkd-ps-slider-layout home-banner">
              <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                  <div className="mkd-ps-image">
                    <picture>
                      <source media="(min-width: 481px)" srcSet="assets/adora.jpg" />
                      <source
                        media="(max-width: 480px)"
                        srcSet="assets/adora-mobile.jpg"
                      />
                      <img
                        className="d-block w-100"
                        src="assets/adora-mobile.jpg"
                        alt="Banner"
                      />
                    </picture>
                    <div className="banner-description">
                      <h2>NEWRY ADORA</h2>
                      <h5>Your Gateway to Luxurious Living! </h5>
                      <h3>
                        Thirumullaivoyal | 2 BHK, 2.5 BHK, 3 BHK | Under Construction
                      </h3>
                      <div className="banner-enquire hide-desktop">
                        <h3>Price Starts at ₹55L*</h3>
                        <a onClick={() => handleButtonClick('Enquire')}
                          itemProp="url"
                          href="#form-landing"
                          target="_self"
                          style={{
                            color: "#fff",
                            backgroundImage:
                              "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                            borderColor: "#fff"
                          }}
                          className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                          data-hover-color="#414347"
                          data-hover-border-color="#fff"
                        >
                          <span className="mkd-btn-text">ENQUIRE NOW</span>
                        </a>
                      </div>
                    </div>
                    <div className="banner-enquire hide-mobile">
                      <h6 style={{ color: "#fff" }}>Price Starts at ₹55L*</h6>
                      <a onClick={() => handleButtonClick('Enquire')}
                        itemProp="url"
                        href="#form-landing"
                        target="_self"
                        style={{
                          color: "#fff",
                          backgroundImage:
                            "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                          borderColor: "#fff"
                        }}
                        className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                        data-hover-color="#414347"
                        data-hover-border-color="#fff"
                      >
                        <span className="mkd-btn-text">ENQUIRE NOW</span>
                      </a>
                    </div>
                  </div>
                  <div className="mkd-ps-image">
                    <picture>
                      <source media="(min-width: 481px)" srcSet="assets/banner/adora-banner.webp" />
                      <source media="(max-width: 480px)" srcSet="assets/banner/adora-mobile.webp" />
                      <img className="d-block w-100" src="assets/banner/adora-mobile.webp" alt="Banner" loading="lazy" />
                    </picture>
                    <div className="banner-description">
                      <h2>NEWRY ADORA</h2>
                      <h5>Your Gateway to Luxurious Living! </h5>
                      <h3>
                        Thirumullaivoyal | 2 BHK, 2.5 BHK, 3 BHK | Under Construction
                      </h3>
                      <div className="banner-enquire hide-desktop">
                        <h3>Price Starts at ₹55L*</h3>
                        <a
                          itemProp="url"
                          href="#form-landing"
                          target="_self"
                          style={{
                            color: "#fff",
                            backgroundImage:
                              "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                            borderColor: "#fff"
                          }}
                          className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                          data-hover-color="#414347"
                          data-hover-border-color="#fff"
                        >
                          <span className="mkd-btn-text">ENQUIRE NOW</span>
                        </a>
                      </div>
                    </div>
                    <div className="banner-enquire hide-mobile">
                      <h6 style={{ color: "#fff" }}>Price Starts at ₹55L*</h6>
                      <a
                        itemProp="url"
                        href="#form-landing"
                        target="_self"
                        style={{
                          color: "#fff",
                          backgroundImage:
                            "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                          borderColor: "#fff"
                        }}
                        className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                        data-hover-color="#414347"
                        data-hover-border-color="#fff"
                      >
                        <span className="mkd-btn-text">ENQUIRE NOW</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_empty_space" style={{ height: 20 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <section className="wpb-content-wrapper hide-desktop">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                  <div className="wpb_column vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_empty_space" style={{ height: 13 }}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">OVERVIEW </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p style={{ textAlign: "justify" }}>
                                    Newry Adora is a premium apartment complex in
                                    Thirumullaivoyal, offering you a variety of options to
                                    suit your needs. Whether you are looking for a
                                    1 BHK, 2 BHK, 2.5 BHK or 3 BHK apartment, you
                                    will find it at Newry Adora. Each apartment is
                                    spacious, elegant and comfortable, with
                                    quality materials and modern amenities. Newry
                                    Adora also has 24/7 security, power backup,
                                    lift facilities, podium level access and more.
                                    Spread over 1.29 acres, with 128 flats, Newry
                                    Adora gives you the ideal balance of community
                                    and privacy. Don’t miss this opportunity to
                                    book your dream home at Newry Adora.{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">HIGHLIGHTS</span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <ul className="overview-features">
                                    <li>
                                      128 flats, built on 1 BHK, 2 BHK, 2.5 BHK
                                      and 3 BHK with stilt and 5 floors in area of
                                      1.25 acres{" "}
                                    </li>
                                    <li>
                                      Constructed using the finest quality
                                      materials to ensure durability and
                                      aesthetics.
                                    </li>
                                    <li>
                                      24/7 Security, Power Backup, and Lift
                                      Facilities for an uninterrupted, and
                                      convenient living.
                                    </li>
                                    <li>
                                      Suitable Landscaping Shall be done as per
                                      Architect design
                                    </li>
                                    <li>
                                      Rainwater Harvesting provision will be done
                                      as per CMWSSB norms.
                                    </li>
                                    <li>
                                      Underground sump of adequate capacity will
                                      be provided.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">DOWNLOADS </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <div className="pdf-icons">
                                    <a href="#enquire-now" onClick={() => handleButtonClick('Adora Brochure')}>
                                      <img src="assets/brouchure-icon.png" />
                                      <p>Brochure</p>
                                    </a>
                                  </div>
                                  <div className="pdf-icons">
                                    <a href="#enquire-now" onClick={() => handleButtonClick('Adora Master Plan')}>
                                      <img src="assets/masterplan.png" />
                                      <p>Master Plan</p>
                                    </a>
                                  </div>
                                  <div className="pdf-icons">
                                    <a href="#enquire-now" onClick={() => handleButtonClick('Adora Floor Plan')}>
                                      <img src="assets/floorplan.png" />
                                      <p>Floor Plan</p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">RERA </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>RERA No - TN/29/BUILDING/394/2023 </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*OVERVIEW - MOBILE*/}
              <div className="mkd-grid-row">
                <div className="mkd-grid-col-12">
                  <div className="mkd-ps-info-item mkd-ps-content-item">
                    <div className="vc_empty_space" style={{ height: 10 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <br />
                    <div className="mkd-container mkd-default-page-template">
                      <div className="mkd-container-inner clearfix">
                        <div className="mkd-grid-row">
                          <div className="mkd-page-content-holder mkd-grid-col-12">
                            <section className="wpb-content-wrapper">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410987782">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner vc_custom_1516800266412">
                                    <div className="wpb_wrapper">
                                      <div className="mkd-elements-holder   mkd-two-columns  mkd-responsive-mode-1024 ">
                                        <div
                                          className="mkd-eh-item    "
                                          data-item-class="mkd-eh-custom-2530"
                                          data-1280-1600="0 0 0 8%"
                                          data-1024-1280="0 0 0 8%"
                                          data-768-1024="0%"
                                          data-680-768="0%"
                                          data-680="0%"
                                        >
                                          <div className="mkd-eh-item-inner">
                                            <div className="mkd-eh-item-content mkd-eh-custom-2530">
                                              <div className="mkd-single-image-holder  mkd-image-behavior-zoom   ">
                                                <div className="mkd-si-inner">
                                                  {/* <img width="800"
                                                                              height="618"
                                                                              src="https://via.placeholder.com/800x618"
                                                                              class="attachment-full size-full"
                                                                              alt="Agam chair"
                                                                              loading="lazy"
                                                                              srcset="https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5.jpg 800w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-670x518.jpg 670w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-300x232.jpg 300w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-768x593.jpg 768w"
                                                                              sizes="(max-width: 800px) 100vw, 800px" /> */}
                                                  <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.480416186022!2d80.1185836!3d13.132068299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52637a24742def%3A0xb57f6474c9d75e9a!2sNewry%20Adora!5e0!3m2!1sen!2sin!4v1709138669894!5m2!1sen!2sin"
                                                    width={600}
                                                    height={450}
                                                    style={{
                                                      border: 0,
                                                      minHeight: 400
                                                    }}
                                                    allowFullScreen=""
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="mkd-eh-item    "
                                          data-item-class="mkd-eh-custom-8795"
                                          data-1280-1600="0 4% 14px 0"
                                          data-1024-1280="0 0% 14px 0"
                                          data-768-1024="30px 0% 14px 0"
                                          data-680-768="30px 0% 14px 0"
                                          data-680="30px 0% 14px 0"
                                          style={{ padding: "0 0 0 4%" }}
                                        >
                                          <div className="mkd-eh-item-inner hide-mobile">
                                            <div
                                              className="mkd-eh-item-content mkd-eh-custom-8795"
                                              style={{ padding: "0 4% 14px 0" }}
                                            >
                                              <div className="mkd-tabs  mkd-tabs-simple">
                                                <ul className="mkd-tabs-nav clearfix">
                                                  <li>
                                                    <a href="#tab-easy-import">
                                                      OVERVIEW
                                                    </a>{" "}
                                                    |
                                                  </li>
                                                  <li>
                                                    <a href="#tab-layouts">
                                                      HIGHLIGHTS
                                                    </a>{" "}
                                                    |
                                                  </li>
                                                  <li>
                                                    <a href="#tab-no-coding">
                                                      DOWNLOADS
                                                    </a>
                                                  </li>
                                                </ul>
                                                <div
                                                  className="mkd-tab-container"
                                                  id="tab-easy-import-525"
                                                >
                                                  <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                      <p style={{ textAlign: "justify" }}>
                                                        Newry Adora is a premium
                                                        apartment complex in
                                                        Thirumullaivoyal, offering you a
                                                        variety of options to suit
                                                        your needs. Whether you
                                                        are looking for a 1 BHK, 2
                                                        BHK, 2.5 BHK or 3 BHK
                                                        apartment, you will find
                                                        it at Newry Adora. Each
                                                        apartment is spacious,
                                                        elegant and comfortable,
                                                        with quality materials and
                                                        modern amenities. Newry
                                                        Adora also has 24/7
                                                        security, power backup,
                                                        lift facilities, podium
                                                        level access and more.
                                                        Spread over 1.29 acres,
                                                        with 128 flats, Newry
                                                        Adora gives you the ideal
                                                        balance of community and
                                                        privacy. Don’t miss this
                                                        opportunity to book your
                                                        dream home at Newry Adora.{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="mkd-tab-container"
                                                  id="tab-layouts-728"
                                                >
                                                  <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                      <ul className="overview-features">
                                                        <li>
                                                          128 flats, built on 1
                                                          BHK, 2 BHK, 2.5 BHK and
                                                          3 BHK with stilt and 5
                                                          floors in area of 1.25
                                                          acres{" "}
                                                        </li>
                                                        <li>
                                                          Constructed using the
                                                          finest quality materials
                                                          to ensure durability and
                                                          aesthetics.
                                                        </li>
                                                        <li>
                                                          24/7 Security, Power
                                                          Backup, and Lift
                                                          Facilities for an
                                                          uninterrupted, and
                                                          convenient living.
                                                        </li>
                                                        <li>
                                                          Suitable Landscaping
                                                          Shall be done as per
                                                          Architect design
                                                        </li>
                                                        <li>
                                                          Rainwater Harvesting
                                                          provision will be done
                                                          as per CMWSSB norms.
                                                        </li>
                                                        <li>
                                                          Underground sump of
                                                          adequate capacity will
                                                          be provided.
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="mkd-tab-container"
                                                  id="tab-no-coding-243"
                                                >
                                                  <div className="wpb_text_column wpb_content_element ">
                                                    <div className="wpb_wrapper">
                                                      <div className="pdf-icons">
                                                        <a href="#form-landing" onClick={() => handleButtonClick('Adora Brochure')}>
                                                          <img src="assets/brouchure-icon.png" />
                                                          <p>Brochure</p>
                                                        </a>
                                                      </div>
                                                      <div className="pdf-icons">
                                                        <a href="#form-landing" onClick={() => handleButtonClick('Adora Master Plan')}>
                                                          <img src="assets/masterplan.png" />
                                                          <p>Master Plan</p>
                                                        </a>
                                                      </div>
                                                      <div className="pdf-icons" onClick={() => handleButtonClick('Adora Floor Plan')}>
                                                        <a href="#form-landing">
                                                          <img src="assets/floorplan.png" />
                                                          <p>Floor Plan</p>
                                                        </a>
                                                      </div>
                                                      <p
                                                        className="hide-mobile"
                                                        style={{
                                                          marginTop: 30,
                                                          color: "#191919",
                                                          textAlign: "center",
                                                          border:
                                                            "1px solid #191919"
                                                        }}
                                                      >
                                                        RERA No -
                                                        TN/29/BUILDING/394/2023{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="vc_empty_space"
                                                style={{ height: 7 }}
                                              >
                                                <span className="vc_empty_space_inner" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_empty_space" style={{ height: 40 }}>
                <span className="vc_empty_space_inner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpb-content-wrapper amenities-title">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div
                        className="mkd-eh-item    "
                        data-item-class="mkd-eh-custom-8773"
                        data-1280-1600="0 0 20px 0"
                        data-1024-1280="0 0 20px 0"
                        data-768-1024="0 0 20px 0"
                        data-680-768="0 0 20px 0"
                        data-680="0px"
                      >
                        <div className="mkd-eh-item-inner">
                          <div
                            className="mkd-eh-item-content mkd-eh-custom-8773"
                            style={{ padding: "0 0 20px 0" }}
                          >
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner">
                                <h2 className="mkd-st-title">Amenities</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mkd-full-width">
        <div className="mkd-full-width-inner">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <section className="wpb-content-wrapper">
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                      <div className="vc_empty_space" style={{ height: 40 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="childern-park"
                                defaultSrc="assets/icons/Newry-web_childern-park.svg"
                                hoverSrc="assets/ongoing/childern-park-animated.svg"
                                description="Children's Park Area"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="jogging"
                                defaultSrc="assets/icons/Newry-web_jogging-track.svg"
                                hoverSrc="assets/ongoing/jogging-animated.svg"
                                description="Jogging Track"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="badminton"
                                defaultSrc="assets/icons/Newry-web_badminton-court.svg"
                                hoverSrc="assets/ongoing/badminton-animated.svg"
                                description="Badminton Court"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="yoga"
                                defaultSrc="assets/icons/Newry-web_yoga-deck.svg"
                                hoverSrc="assets/ongoing/yoga-animated.svg"
                                description="Yoga Deck"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="meditation"
                                defaultSrc="assets/icons/Newry-web_meditation-zone.svg"
                                hoverSrc="assets/ongoing/meditation-animated.svg"
                                description="Meditation Zone"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="web_street"
                                defaultSrc="assets/icons/Newry-web_street-light.svg"
                                hoverSrc="assets/ongoing/web_street-animated.svg"
                                description="Street Lights"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-11 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="gazebo"
                                defaultSrc="assets/icons/Newry-web_gazebo.svg"
                                hoverSrc="assets/ongoing/gazebo-animated.svg"
                                description="Gazebo"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <ProjectAmenity
                                id="gym"
                                defaultSrc="assets/icons/gyms.svg"
                                hoverSrc="assets/ongoing/gym-animated.svg"
                                description="GYM"
                                iconStyle={{ width: 100, height: 100 }}
                                boxStyle={{ textAlign: 'center' }}
                              />
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <section className="wpb-content-wrapper amenities-title">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div
                        className="mkd-eh-item    "
                        data-item-class="mkd-eh-custom-8773"
                        data-1280-1600="0 0 20px 0"
                        data-1024-1280="0 0 20px 0"
                        data-768-1024="0 0 20px 0"
                        data-680-768="0 0 20px 0"
                        data-680="0px"
                      >
                        <div className="mkd-eh-item-inner">
                          <div
                            className="mkd-eh-item-content mkd-eh-custom-8773"
                            style={{ padding: "0 0 20px 0" }}
                          >
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner">
                                <h2 className="mkd-st-title">Gallery</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mkd-container mkd-default-page-template">
        <div className="mkd-container-inner clearfix">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <section className="wpb-content-wrapper">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410942366">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="mkd-tabs  mkd-tabs-simple" align="center">
                          <ul className="mkd-tabs-nav clearfix">
                            <li>
                              <a href="#tab-about-us">
                                External Views &nbsp;&nbsp;
                              </a>
                            </li>
                            <li>
                              <a href="#tab-design">
                                Internal views &nbsp;&nbsp;
                              </a>
                            </li>
                            <li>
                              <a href="#tab-trends">Project Progress </a>
                            </li>
                          </ul>
                          <div
                            className="mkd-tab-container"
                            id="tab-about-us-249" align="left"
                          >
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <div className="mkd-full-width">
                                  <div className="mkd-full-width-inner">
                                    <div className="mkd-grid-row">
                                      <div className="mkd-page-content-holder mkd-grid-col-12">
                                        <section className="wpb-content-wrapper">
                                          <div className="mkd-row-grid-section-wrapper ">
                                            <div className="mkd-row-grid-section">
                                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1517920277412">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                  <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                      <div
                                                        className="mkd-portfolio-list-holder"
                                                        data-type="masonry"
                                                      >
                                                        <div className="mkd-pl-inner mkd-outer-space clearfix">
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/08/web-image-02.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/08/web-image-01.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/08/web-image-03.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Aerial-View.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Aerial-View_2.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Day-View_03.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Podium-view.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Podium-view_2.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Night-View_01.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Night-View_02.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mkd-tab-container" id="tab-design-198">
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <div className="mkd-full-width">
                                  <div className="mkd-full-width-inner">
                                    <div className="mkd-grid-row">
                                      <div className="mkd-page-content-holder mkd-grid-col-12">
                                        <section className="wpb-content-wrapper">
                                          <div className="mkd-row-grid-section-wrapper ">
                                            <div className="mkd-row-grid-section">
                                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1517920277412">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                  <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                      <div
                                                        className="mkd-portfolio-list-holder"
                                                        data-type="masonry"
                                                      >
                                                        <div className="mkd-pl-inner mkd-outer-space clearfix">
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Lobby.png"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Unit_Living-view2.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Unit_Dining-view2.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Unit_Dining-view1.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Unit_Master-bed.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Unit_Bedroom.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="https://www.newry.co.in/wp-content/uploads/2023/11/Newry_Adora_Unit_Kitchen.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mkd-tab-container" id="tab-trends-268">
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <div className="mkd-full-width">
                                  <div className="mkd-full-width-inner">
                                    <div className="mkd-grid-row">
                                      <div className="mkd-page-content-holder mkd-grid-col-12">
                                        <section className="wpb-content-wrapper">
                                          <div className="mkd-row-grid-section-wrapper ">
                                            <div className="mkd-row-grid-section">
                                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1517920277412">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                  <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                      <div
                                                        className="mkd-portfolio-list-holder"
                                                        data-type="masonry"
                                                      >
                                                        <div className="mkd-pl-inner mkd-outer-space clearfix third-col">
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora1.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>

                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora2.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora3.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora4.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora5.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora6.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora7.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora8.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                          <article className="mkd-pl-item mkd-item-space mkd-pl-masonry-default post-1536 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-architecture-project">
                                                            <div className="mkd-pl-item-inner">
                                                              <div className="mkd-pli-image">
                                                                <img
                                                                  src="assets/adora/adora9.jpg"
                                                                  className="attachment-full size-full wp-post-image"
                                                                  alt="a"
                                                                />
                                                              </div>
                                                            </div>
                                                          </article>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <section className="wpb-content-wrapper location-advantages">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div
                        className="mkd-eh-item    "
                        data-item-class="mkd-eh-custom-8773"
                        data-1280-1600="0 0 20px 0"
                        data-1024-1280="0 0 20px 0"
                        data-768-1024="0 0 20px 0"
                        data-680-768="0 0 20px 0"
                        data-680="0px"
                      >
                        <div className="mkd-eh-item-inner">
                          <div
                            className="mkd-eh-item-content mkd-eh-custom-8773"
                            style={{ padding: "0 0 20px 0" }}
                          >
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner">
                                <h2 className="mkd-st-title">
                                  Location Advantage
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mkd-full-width">
        <div className="mkd-full-width-inner">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <section className="wpb-content-wrapper location-advantages">
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <ProjectLocationIcons
                                  id="schools"
                                  defaultSrc="assets/animated/schools1.svg"
                                  hoverSrc="assets/animated/schools.svg"
                                  titleText="Schools"
                                  iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                />
                                <ul>
                                  <li>
                                    <span
                                      style={{
                                        width: 215,
                                        display: "inline-block"
                                      }}
                                    >
                                      Sri Venkateswara School:
                                    </span>{" "}
                                    <strong>2 mks</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 215,
                                        display: "inline-block"
                                      }}
                                    >
                                      Kendriya Vidyalaya School:
                                    </span>{" "}
                                    <strong>2 kms </strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 215,
                                        display: "inline-block"
                                      }}
                                    >
                                      Nazareth School:
                                    </span>{" "}
                                    <strong>2 kms</strong>{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <ProjectLocationIcons
                                  id="colleges"
                                  defaultSrc="assets/animated/colleges.svg"
                                  hoverSrc="assets/animated/COLLEGES1.svg"
                                  titleText="Colleges"
                                  iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                />
                                <ul>
                                  <li>
                                    <span
                                      style={{
                                        width: 255,
                                        display: "inline-block"
                                      }}
                                    >
                                      Murugappa Polytechnic College:
                                    </span>{" "}
                                    <strong>1.2 kms</strong>{" "}
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 255,
                                        display: "inline-block"
                                      }}
                                    >
                                      Hindu College:
                                    </span>{" "}
                                    <strong>6 kms</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 255,
                                        display: "inline-block"
                                      }}
                                    >
                                      S.A Polytechnic College:
                                    </span>{" "}
                                    <strong>9 kms</strong>{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <ProjectLocationIcons
                                  id="shopping"
                                  defaultSrc="assets/animated/shopping1.svg"
                                  hoverSrc="assets/animated/SHOPPING.svg"
                                  titleText="Shopping"
                                  iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                />
                                <ul>
                                  <li>
                                    <span
                                      style={{
                                        width: 170,
                                        display: "inline-block"
                                      }}
                                    >
                                      Moer Supermarket:
                                    </span>{" "}
                                    <strong>1 km</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 170,
                                        display: "inline-block"
                                      }}
                                    >
                                      Udhaya Supermarket:
                                    </span>{" "}
                                    <strong>2.5 kms</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 170,
                                        display: "inline-block"
                                      }}
                                    >
                                      Ponnu Supermarket:
                                    </span>{" "}
                                    <strong>5 kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <ProjectLocationIcons
                                  id="publicspaces"
                                  defaultSrc="assets/animated/publicspaces.svg"
                                  hoverSrc="assets/animated/PUBLICSPACES1.svg"
                                  titleText="Public Spaces"
                                  iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                />
                                <ul>
                                  <li>
                                    AGS Cinemas: <strong>3 kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-11 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <ProjectLocationIcons
                                  id="healthcare"
                                  defaultSrc="assets/animated/healthcare.svg"
                                  hoverSrc="assets/animated/HEALTHCARE1.svg"
                                  titleText="Health Care"
                                  iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                />
                                <ul>
                                  <li>
                                    <span
                                      style={{
                                        width: 215,
                                        display: "inline-block"
                                      }}
                                    >
                                      Be Well Hospital:
                                    </span>{" "}
                                    <strong>2.7 Kms</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 215,
                                        display: "inline-block"
                                      }}
                                    >
                                      Sir Ivan Stedeford Hospital:
                                    </span>{" "}
                                    <strong>3 Kms</strong>{" "}
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 215,
                                        display: "inline-block"
                                      }}
                                    >
                                      HVF Hospital:
                                    </span>{" "}
                                    <strong>3 Kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                              <div className="mkd-iwt-content">
                                <ProjectLocationIcons
                                  id="others"
                                  defaultSrc="assets/animated/others1.svg"
                                  hoverSrc="assets/animated/OTHERS.svg"
                                  titleText="Others"
                                  iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                />
                                <ul>
                                  <li>
                                    <span
                                      style={{
                                        width: 185,
                                        display: "inline-block"
                                      }}
                                    >
                                      Tube Products of India:
                                    </span>{" "}
                                    <strong>2 kms</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 185,
                                        display: "inline-block"
                                      }}
                                    >
                                      OCF Avadi:
                                    </span>{" "}
                                    <strong>3.8 kms</strong>
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        width: 185,
                                        display: "inline-block"
                                      }}
                                    >
                                      HVF Avadi:
                                    </span>{" "}
                                    <strong>4.5 kms</strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: 38 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        data-parallax-bg-image="assets/adora-walkthrough.jpg"
        data-parallax-bg-speed={1}
        className="vc_row wpb_row vc_row-fluid vc_custom_1518019336900 vc_row-has-fill mkd-parallax-row-holder mkd-content-aligment-center"
      >
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                <div
                  className="mkd-eh-item    "
                  data-item-class="mkd-eh-custom-9497"
                  data-1280-1600="12.3% 0 15.4%"
                  data-1024-1280="12.3% 0 15.4%"
                  data-768-1024="11.3% 0 15.4%"
                  data-680-768="10.3% 0 15.4%"
                  data-680="10.3% 0 15.4%"
                >
                  <div className="mkd-eh-item-inner">
                    <div
                      className="mkd-eh-item-content mkd-eh-custom-9497"
                      style={{ padding: "10.2% 0 12.3%" }}
                    >
                      <h1
                        className="mkd-custom-font-holder  mkd-cf-6143  "
                        style={{ color: "#ffffff" }}
                        data-item-class="mkd-cf-6143"
                      >
                        Walkthrough
                      </h1>
                      <div className="mkd-video-button-holder  ">
                        <div className="mkd-video-button-image"></div>
                        <a
                          className="mkd-video-button-play"
                          style={{ color: "#ffffff", fontSize: 50 }}
                          href="https://www.youtube.com/watch?v=MK_bqICoG4U"
                          target="_self"
                          data-rel="prettyPhoto[video_button_pretty_photo_984]"
                        >
                          <span className="mkd-video-button-play-inner">
                            <span className="arrow_triangle-right_alt" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkd-container mkd-default-page-template">
        <div className="mkd-container-inner clearfix">
          <div className="mkd-grid-row">
            <div className="mkd-page-content-holder mkd-grid-col-12">
              <br />
              <br />
              <br />
              <br />
              <section className="wpb-content-wrapper">
                <div className="mkd-row-grid-section-wrapper ">
                  <div className="mkd-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner vc_custom_1516007647796">
                          <div className="wpb_wrapper">
                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                              <div
                                className="mkd-eh-item    "
                                data-item-class="mkd-eh-custom-8773"
                                data-1280-1600="0 0 20px 0"
                                data-1024-1280="0 0 20px 0"
                                data-768-1024="0 0 20px 0"
                                data-680-768="0 0 20px 0"
                                data-680="0px"
                              >
                                <div className="mkd-eh-item-inner">
                                  <div
                                    className="mkd-eh-item-content mkd-eh-custom-8773"
                                    style={{ padding: "0 0 20px 0" }}
                                  >
                                    <div className="mkd-section-title-holder  ">
                                      <div className="mkd-st-inner">
                                        <h2 className="mkd-st-title">
                                          Frequently Asked Questions
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wpb-content-wrapper">
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                  <div className="wpb_column vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_empty_space" style={{ height: 13 }}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What is the starting price of Newry Adora?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The starting price of Newry Adora is INR 55L*
                                    onwards.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What are the types of houses available at Newry
                              Adora?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The Apartments of 2 BHK, 2.5 BHK and 3 BHK
                                    Available
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What is the overall area of the Newry Adora?{" "}
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The total area size of Newry Adora is 1.25
                                    Acres.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 id="form-landing" className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              Where is Newry Adora located?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    Newry Adora is located at Thirumullaivoyal,
                                    Chennai, Tamil Nadu 600062
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mkd-accordion-title">
                            <span className="mkd-accordion-mark">
                              <span className="mkd_icon_plus icon_plus" />
                              <span className="mkd_icon_minus icon_minus-06" />
                            </span>
                            <span className="mkd-tab-title">
                              What are the key amenities at Newry Adora?
                            </span>
                          </h6>
                          <div className="mkd-accordion-content">
                            <div className="mkd-accordion-content-inner">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    The amenities at Newry Adora include 24/7
                                    security, power backup, lift facilities, GYM
                                    and more.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="">
        <br />
        <br />
        <div className="mkd-content-inner">
          <div className="mkd-full-width">
            <div className="mkd-full-width-inner">
              <div className="mkd-grid-row">
                <div className="mkd-page-content-holder mkd-grid-col-12">
                  <section className="wpb-content-wrapper">
                    <div className="vc_row wpb_row vc_row-fluid">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkd-google-map-holder">
                              <input
                                type="hidden"
                                className="mkd-snazzy-map"
                                defaultValue=""
                              />
                              <div className="mkd-google-map-overlay" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<div id="enquire-now" class="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768" style="background-color:#ebebeb; background-image: url(https://entre.qodeinteractive.com/wp-content/uploads/2018/01/contact-background-img.jpg)">*/}
                    <div
                      id="enquire-now"
                      className="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768"
                      style={{ backgroundColor: "#ebebeb" }}
                    >
                      <div className="mkd-row-grid-section">
                        <div className="vc_row wpb_row vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                          <div
                                            className="mkd-eh-item    mkd-element-from-bottom"
                                            data-item-class="mkd-eh-custom-8629"
                                            data-animation="mkd-element-from-bottom"
                                            data-1280-1600="60px 0 0 8px"
                                            data-1024-1280="60px 0 0 8px"
                                            data-768-1024="60px 183px 0 0"
                                            data-680-768="60px 0 0px 0"
                                            data-680="60px 0 0 0"
                                          >
                                            <div className="mkd-eh-item-inner">
                                              <div
                                                className="mkd-eh-item-content mkd-eh-custom-8629"
                                                style={{
                                                  padding:
                                                    "60px 0 0 8px !important"
                                                }}
                                              >
                                                <div className="wpb_text_column wpb_content_element  vc_custom_1516695756662">
                                                  <div className="wpb_wrapper">
                                                    <h6
                                                      style={{
                                                        fontSize: 25,
                                                        paddingBottom: 6,
                                                        fontFamily: "metropolis"
                                                      }}
                                                    >
                                                      ENQUIRE NOW
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div
                                                  className="vc_empty_space"
                                                  style={{ height: 23 }}
                                                >
                                                  <span className="vc_empty_space_inner" />
                                                </div>
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      Wish to get a call back from
                                                      our team? Fill in your
                                                      details.
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      For bookings and more
                                                      information, <br />
                                                      <br />
                                                      <a href="tel:9994144999">
                                                        <span className="fa fa-phone" />{" "}
                                                        <strong>
                                                          +91 76 9993 9993
                                                        </strong>
                                                      </a>
                                                      <br />
                                                      <br />
                                                      <a href="mailto:info@newry.co.in">
                                                        <span className="fa fa-envelope" />{" "}
                                                        <strong>
                                                          info@newry.co.in
                                                        </strong>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  className="vc_empty_space"
                                                  style={{ height: 28 }}
                                                >
                                                  <span className="vc_empty_space_inner" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <EnquiryForm clickedButton={clickedButton} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-static-bottom">
      <div className="footer-icon">
        <img src="assets/whatsapp.png" />
      </div>
      <div className="footer-icon">
        <img src="assets/call.png" />
      </div>
      <div className="footer-icon">
        <a href="#">
          <img src="assets/enquire.png" />
        </a>
      </div>
    </div>
  </>;
};

export default Adora;