import React, { useRef, useState } from 'react';
import ProjectLocationIcons from "../modules/ProjectLocationIcons";
import ProjectAmenity from "../modules/ProjectAmenity";
import EnquiryForm from "../modules/EnquiryForm";

const CloverCounty = () => {
    const formRef = useRef(null);
    const [clickedButton, setClickedButton] = useState('');
  
    const handleButtonClick = (buttonType) => {
      setClickedButton(buttonType);
      //formRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    return <>
    <link rel="stylesheet" href="css/CloverCounty.module.css" />
        <div className="mkd-content">
            <a id="mkd-back-to-top" href="#">
                <span className="mkd-icon-stack">
                    <span aria-hidden="true" className="mkd-icon-font-elegant arrow_up  " />
                </span>
            </a>
            <div className="mkd-content-inner">
                <div className="mkd-container">
                    <div className="mkd-container clearfix">
                        <div className="mkd-portfolio-single-holder mkd-ps-slider-layout home-banner">
                            <div className="mkd-ps-image-holder">
                                <div className="mkd-ps-image mkd-owl-slider">
                                    <div className="mkd-ps-image">
                                        <picture>
                                            <source
                                                media="(min-width: 481px)"
                                                srcSet="assets/ongoing/clover.jpg"
                                            />
                                            <source
                                                media="(max-width: 480px)"
                                                srcSet="assets/ongoing/clover-mobile.jpg"
                                            />
                                            <img
                                                className="d-block w-100"
                                                src="assets/ongoing/clover-mobile.jpg"
                                                alt="Banner"
                                            />
                                        </picture>
                                        <div className="banner-description">
                                            <h2>Newry Clover County</h2>
                                            <h5>Premium Residential Villa Plots</h5>
                                            <h3>Parivakkam | Ready to Construction</h3>
                                            <div className="banner-enquire hide-desktop">
                                                <h3>Price Starts at ₹28L*</h3>
                                                <a onClick={() => handleButtonClick('Enquire')}
                                                    itemProp="url"
                                                    href="#form-landing"
                                                    target="_self"
                                                    style={{
                                                        color: "#fff",
                                                        backgroundImage:
                                                            "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                                                        borderColor: "#fff"
                                                    }}
                                                    className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                                                    data-hover-color="#414347"
                                                    data-hover-border-color="#fff"
                                                >
                                                    <span className="mkd-btn-text">ENQUIRE NOW</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="banner-enquire hide-mobile">
                                            <h6 style={{ color: "#fff" }}>Price Starts at ₹28L*</h6>
                                            <a onClick={() => handleButtonClick('Enquire')}
                                                itemProp="url"
                                                href="#form-landing"
                                                target="_self"
                                                style={{
                                                    color: "#fff",
                                                    backgroundImage:
                                                        "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                                                    borderColor: "#fff"
                                                }}
                                                className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                                                data-hover-color="#414347"
                                                data-hover-border-color="#fff"
                                            >
                                                <span className="mkd-btn-text">ENQUIRE NOW</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mkd-ps-image">
                                        <picture>
                                            <source
                                                media="(min-width: 481px)"
                                                srcSet="assets/ongoing/clover1.jpg"
                                            />
                                            <source
                                                media="(max-width: 480px)"
                                                srcSet="assets/ongoing/clover1-mobile.jpg"
                                            />
                                            <img
                                                className="d-block w-100"
                                                src="assets/ongoing/clover1-mobile.jpg"
                                                alt="Banner"
                                            />
                                        </picture>
                                        <div className="banner-description">
                                            <h2>Newry Clover County</h2>
                                            <h5>Premium Residential Villa Plots</h5>
                                            <h3>Parivakkam | Ready to Construction</h3>
                                            <div className="banner-enquire hide-desktop">
                                                <h3>Price Starts at ₹28L*</h3>
                                                <a  onClick={() => handleButtonClick('Enquire')}
                                                    itemProp="url"
                                                    href="#form-landing"
                                                    target="_self"
                                                    style={{
                                                        color: "#fff",
                                                        backgroundImage:
                                                            "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                                                        borderColor: "#fff"
                                                    }}
                                                    className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                                                    data-hover-color="#414347"
                                                    data-hover-border-color="#fff"
                                                >
                                                    <span className="mkd-btn-text">ENQUIRE NOW</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="banner-enquire hide-mobile">
                                            <h6 style={{ color: "#fff" }}>Price Starts at ₹28L*</h6>
                                            <a  onClick={() => handleButtonClick('Enquire')}
                                                itemProp="url"
                                                href="#form-landing"
                                                target="_self"
                                                style={{
                                                    color: "#fff",
                                                    backgroundImage:
                                                        "linear-gradient(to right,#414347 50%,#fff 50%),linear-gradient(to right,#fff 50%,transparent 50%)",
                                                    borderColor: "#fff"
                                                }}
                                                className="mkd-btn mkd-btn-medium mkd-btn-solid mkd-btn-custom-border-hover mkd-btn-custom-hover-color"
                                                data-hover-color="#414347"
                                                data-hover-border-color="#fff"
                                            >
                                                <span className="mkd-btn-text">ENQUIRE NOW</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 20 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                            <section className="wpb-content-wrapper hide-desktop">
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                                    <div className="wpb_column vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_empty_space" style={{ height: 13 }}>
                                                    <span className="vc_empty_space_inner" />
                                                </div>
                                                <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">OVERVIEW </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p style={{ textAlign: "justify" }}>
                                                                        Newry Clover County is a plotted development
                                                                        project in Poonamallee - Avadi Road, part of
                                                                        the Newry Fortune City Integrated Township. It
                                                                        offers you the opportunity to build your dream
                                                                        home according to your preferences, budget and
                                                                        design. Newry Clover County plots are
                                                                        well-equipped with storm water drain,
                                                                        electrical cable trench, blacktop road and
                                                                        street lights. The project has only 224 plots
                                                                        in 5.78 acres of serene land. Newry Clover
                                                                        County is the perfect place to enjoy an
                                                                        independent, secured and comfortable living.{" "}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">HIGHLIGHTS</span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <ul className="overview-features">
                                                                        <li>Perfectly Planned Integrated Township</li>
                                                                        <li>
                                                                            Well Secured and Thriving Gated Community
                                                                        </li>
                                                                        <li>18 Metres Wide Blacktop Roads </li>
                                                                        <li>
                                                                            State-of-the-Art Infrastructure Facilities
                                                                        </li>
                                                                        <li>Surface Storm Water Drainage </li>
                                                                        <li>
                                                                            Underground Electricity &amp; Network Cables
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">DOWNLOADS </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <div className="pdf-icons">
                                                                        <a href="#enquire-now"  onClick={() => handleButtonClick('Clover Brochure')}>
                                                                            <img src="assets/brouchure-icon.png" />
                                                                            <p>Brochure</p>
                                                                        </a>
                                                                    </div>
                                                                    <div className="pdf-icons">
                                                                        <a href="#enquire-now"  onClick={() => handleButtonClick('Clover Master Plan')}>
                                                                            <img src="assets/masterplan.png" />
                                                                            <p>Master Plan</p>
                                                                        </a>
                                                                    </div>
                                                                    <div className="pdf-icons">
                                                                        <a href="#enquire-now" onClick={() => handleButtonClick('Clover Floor Plan')}>
                                                                            <img src="assets/floorplan.png" />
                                                                            <p>Layout Plan</p>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">RERA </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>RERA No - TN/02/Layout/4423/2022 </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*OVERVIEW - MOBILE*/}
                            <div className="mkd-grid-row">
                                <div className="mkd-grid-col-12">
                                    <div className="mkd-ps-info-item mkd-ps-content-item">
                                        <div className="vc_empty_space" style={{ height: 10 }}>
                                            <span className="vc_empty_space_inner" />
                                        </div>
                                        <br />
                                        <div className="mkd-container mkd-default-page-template">
                                            <div className="mkd-container-inner clearfix">
                                                <div className="mkd-grid-row">
                                                    <div className="mkd-page-content-holder mkd-grid-col-12">
                                                        <section className="wpb-content-wrapper">
                                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410987782">
                                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div className="vc_column-inner vc_custom_1516800266412">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-elements-holder   mkd-two-columns  mkd-responsive-mode-1024 ">
                                                                                <div
                                                                                    className="mkd-eh-item    "
                                                                                    data-item-class="mkd-eh-custom-2530"
                                                                                    data-1280-1600={0}
                                                                                    data-1024-1280={0}
                                                                                    data-768-1024="0%"
                                                                                    data-680-768="0%"
                                                                                    data-680="0%"
                                                                                >
                                                                                    <div className="mkd-eh-item-inner">
                                                                                        <div className="mkd-eh-item-content mkd-eh-custom-2530">
                                                                                            <div className="mkd-single-image-holder  mkd-image-behavior-zoom   ">
                                                                                                <div className="mkd-si-inner">
                                                                                                    {/* <img width="800"
                                                                                height="618"
                                                                                src="https://via.placeholder.com/800x618"
                                                                                class="attachment-full size-full"
                                                                                alt="Agam chair"
                                                                                loading="lazy"
                                                                                srcset="https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5.jpg 800w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-670x518.jpg 670w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-300x232.jpg 300w, https://entre.qodeinteractive.com/wp-content/uploads/2018/01/h1-img-5-768x593.jpg 768w"
                                                                                sizes="(max-width: 800px) 100vw, 800px" /> */}
                                                                                                    <iframe
                                                                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17529.729348471978!2d80.10278699999999!3d13.067537699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261a429b7d7f1%3A0xadfd815f70886e64!2sNewry%20Clover%20County!5e1!3m2!1sen!2sin!4v1741685021362!5m2!1sen!2sin"
                                                                                                        width={600}
                                                                                                        height={450}
                                                                                                        style={{
                                                                                                            border: 0,
                                                                                                            minHeight: 400
                                                                                                        }}
                                                                                                        allowFullScreen=""
                                                                                                        loading="lazy"
                                                                                                        referrerPolicy="no-referrer-when-downgrade"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="mkd-eh-item    "
                                                                                    data-item-class="mkd-eh-custom-8795"
                                                                                    data-1280-1600="0 4% 14px 0"
                                                                                    data-1024-1280="0 0% 14px 0"
                                                                                    data-768-1024="30px 0% 14px 0"
                                                                                    data-680-768="30px 0% 14px 0"
                                                                                    data-680="30px 0% 14px 0"
                                                                                    style={{ padding: "0 0 0 4%" }}
                                                                                >
                                                                                    <div className="mkd-eh-item-inner hide-mobile">
                                                                                        <div
                                                                                            className="mkd-eh-item-content mkd-eh-custom-8795"
                                                                                            style={{ padding: "0 4% 14px 0" }}
                                                                                        >
                                                                                            <div className="mkd-tabs  mkd-tabs-simple">
                                                                                                <ul className="mkd-tabs-nav clearfix">
                                                                                                    <li>
                                                                                                        <a href="#tab-easy-import">
                                                                                                            OVERVIEW
                                                                                                        </a>{" "}
                                                                                                        |
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <a href="#tab-layouts">
                                                                                                            HIGHLIGHTS
                                                                                                        </a>{" "}
                                                                                                        |
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <a href="#tab-no-coding">
                                                                                                            DOWNLOADS
                                                                                                        </a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div
                                                                                                    className="mkd-tab-container"
                                                                                                    id="tab-easy-import-525"
                                                                                                >
                                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                                        <div className="wpb_wrapper">
                                                                                                            <p style={{ textAlign: "justify"}}>
                                                                                                                Newry Clover County is a
                                                                                                                plotted development
                                                                                                                project in Poonamallee -
                                                                                                                Avadi Road, part of the
                                                                                                                Newry Fortune City
                                                                                                                Integrated Township. It
                                                                                                                offers you the opportunity
                                                                                                                to build your dream home
                                                                                                                according to your
                                                                                                                preferences, budget and
                                                                                                                design. Newry Clover
                                                                                                                County plots are
                                                                                                                well-equipped with storm
                                                                                                                water drain, electrical
                                                                                                                cable trench, blacktop
                                                                                                                road and street lights.
                                                                                                                The project has only 224
                                                                                                                plots in 5.78 acres of
                                                                                                                serene land. Newry Clover
                                                                                                                County is the perfect
                                                                                                                place to enjoy an
                                                                                                                independent, secured and
                                                                                                                comfortable living.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-tab-container"
                                                                                                    id="tab-layouts-728"
                                                                                                >
                                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                                        <div className="wpb_wrapper">
                                                                                                            <ul className="overview-features">
                                                                                                                <li>
                                                                                                                    Perfectly Planned
                                                                                                                    Integrated Township
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    Well Secured and
                                                                                                                    Thriving Gated Community
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    18 Metres Wide Blacktop
                                                                                                                    Roads{" "}
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    State-of-the-Art
                                                                                                                    Infrastructure
                                                                                                                    Facilities
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    Surface Storm Water
                                                                                                                    Drainage{" "}
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    Underground Electricity
                                                                                                                    &amp; Network Cables
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-tab-container"
                                                                                                    id="tab-no-coding-243"
                                                                                                >
                                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                                        <div className="wpb_wrapper">
                                                                                                            <div className="pdf-icons">
                                                                                                                <a href="#form-landing" onClick={() => handleButtonClick('Clover Brochure')}>
                                                                                                                    <img src="assets/brouchure-icon.png" />
                                                                                                                    <p>Brochure</p>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div className="pdf-icons">
                                                                                                                <a href="#form-landing" onClick={() => handleButtonClick('Clover Master Plan')}>
                                                                                                                    <img src="assets/masterplan.png" />
                                                                                                                    <p>Master Plan</p>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div className="pdf-icons">
                                                                                                                <a href="#form-landing" onClick={() => handleButtonClick('Clover Floor Plan')}>
                                                                                                                    <img src="assets/floorplan.png" />
                                                                                                                    <p>Layout Plan</p>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <p
                                                                                                                className="hide-mobile"
                                                                                                                style={{
                                                                                                                    marginTop: 30,
                                                                                                                    color: "#191919",
                                                                                                                    textAlign: "center",
                                                                                                                    border:
                                                                                                                        "1px solid #191919"
                                                                                                                }}
                                                                                                            >
                                                                                                                {" "}
                                                                                                                RERA No -
                                                                                                                TN/02/Layout/4423/2022{" "}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="vc_empty_space"
                                                                                                style={{ height: 7 }}
                                                                                            >
                                                                                                <span className="vc_empty_space_inner" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_empty_space" style={{ height: 40 }}>
                                <span className="vc_empty_space_inner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="wpb-content-wrapper amenities-title">
                <div className="mkd-row-grid-section-wrapper ">
                    <div className="mkd-row-grid-section">
                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1516007647796">
                                    <div className="wpb_wrapper">
                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                            <div
                                                className="mkd-eh-item    "
                                                data-item-class="mkd-eh-custom-8773"
                                                data-1280-1600="0 0 20px 0"
                                                data-1024-1280="0 0 20px 0"
                                                data-768-1024="0 0 20px 0"
                                                data-680-768="0 0 20px 0"
                                                data-680="0px"
                                            >
                                                <div className="mkd-eh-item-inner">
                                                    <div
                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                        style={{ padding: "0 0 20px 0" }}
                                                    >
                                                        <div className="mkd-section-title-holder  ">
                                                            <div className="mkd-st-inner">
                                                                <h2 className="mkd-st-title">Amenities</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="mkd-full-width">
                <div className="mkd-full-width-inner">
                    <div className="mkd-grid-row">
                        <div className="mkd-page-content-holder mkd-grid-col-12">
                            <section className="wpb-content-wrapper">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_empty_space" style={{ height: 40 }}>
                                            <span className="vc_empty_space_inner" />
                                        </div>
                                        <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="web_green"
                                                            defaultSrc="assets/icons/Newry-web_green-landscaped.svg"
                                                            hoverSrc="assets/ongoing/green-landscaped-animated.svg"
                                                            description="Green Landscaped Park"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        /> 
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="basket"
                                                            defaultSrc="assets/services/basketball.svg"
                                                            hoverSrc="assets/services/basketball-animated.svg"
                                                            description="Basketball Court"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        /> 
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-11 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="gazebo"
                                                            defaultSrc="assets/icons/Newry-web_gazebo.svg"
                                                            hoverSrc="assets/ongoing/gazebo-animated.svg"
                                                            description="Gazebo"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        />
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="web_street"
                                                            defaultSrc="assets/icons/Newry-web_street-light.svg"
                                                            hoverSrc="assets/ongoing/web_street-animated.svg"
                                                            description="Street Lights"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        /> 
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="jogging"
                                                            defaultSrc="assets/icons/Newry-web_jogging-track.svg"
                                                            hoverSrc="assets/ongoing/jogging-animated.svg"
                                                            description="Jogging Track"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        />
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="yoga"
                                                            defaultSrc="assets/icons/Newry-web_yoga-deck.svg"
                                                            hoverSrc="assets/ongoing/yoga-animated.svg"
                                                            description="Yoga Deck"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        />
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium"> 
                                                        <ProjectAmenity
                                                            id="water"
                                                            defaultSrc="assets/icons/Newry-web_storm-water-drain.svg"
                                                            hoverSrc="assets/ongoing/water-animated.svg"
                                                            description="Storm Water Drain"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        />       
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                            id="badminton"
                                                            defaultSrc="assets/icons/Newry-web_badminton-court.svg"
                                                            hoverSrc="assets/ongoing/badminton-animated.svg"
                                                            description="Badminton Court"
                                                            iconStyle={{ width: 100, height: 100 }}
                                                            boxStyle={{ textAlign: 'center' }}
                                                        />  
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516871268525 vc_column-gap-35">
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                            <ProjectAmenity
                                                                id="meditation"
                                                                defaultSrc="assets/icons/Newry-web_meditation-zone.svg"
                                                                hoverSrc="assets/ongoing/meditation-animated.svg"
                                                                description="Meditation Zone"
                                                                iconStyle={{ width: 100, height: 100 }}
                                                                boxStyle={{ textAlign: 'center' }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                            <ProjectAmenity
                                                                id="cricket"
                                                                defaultSrc="assets/animated/cricket.svg"
                                                                hoverSrc="assets/animated/cricket1.svg"
                                                                description="Cricket Net"
                                                                iconStyle={{ width: 100, height: 100 }}
                                                                boxStyle={{ textAlign: 'center' }}
                                                            /> 
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                                id="childern-park"
                                                                defaultSrc="assets/icons/Newry-web_childern-park.svg"
                                                                hoverSrc="assets/ongoing/childern-park-animated.svg"
                                                                description="Children's Park Area"
                                                                iconStyle={{ width: 100, height: 100 }}
                                                                boxStyle={{ textAlign: 'center' }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-12 vc_col-xs-12"
                                                style={{ textAlign: "center" }}
                                            >
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                        <ProjectAmenity
                                                                id="bridge"
                                                                defaultSrc="assets/animated/BRIDGE.svg"
                                                                hoverSrc="assets/animated/bridge1.svg"
                                                                description="Channel Bridge"
                                                                iconStyle={{ width: 100, height: 100 }}
                                                                boxStyle={{ textAlign: 'center' }}
                                                            /> 
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <section className="wpb-content-wrapper location-advantages">
                <div className="mkd-row-grid-section-wrapper ">
                    <div className="mkd-row-grid-section">
                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1516007647796">
                                    <div className="wpb_wrapper">
                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                            <div
                                                className="mkd-eh-item    "
                                                data-item-class="mkd-eh-custom-8773"
                                                data-1280-1600="0 0 20px 0"
                                                data-1024-1280="0 0 20px 0"
                                                data-768-1024="0 0 20px 0"
                                                data-680-768="0 0 20px 0"
                                                data-680="0px"
                                            >
                                                <div className="mkd-eh-item-inner">
                                                    <div
                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                        style={{ padding: "0 0 20px 0" }}
                                                    >
                                                        <div className="mkd-section-title-holder  ">
                                                            <div className="mkd-st-inner">
                                                                <h2 className="mkd-st-title">
                                                                    Location Advantage
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="mkd-full-width">
                <div className="mkd-full-width-inner">
                    <div className="mkd-grid-row">
                        <div className="mkd-page-content-holder mkd-grid-col-12">
                            <section className="wpb-content-wrapper location-advantages">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_column-gap-35">
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                            <div className="mkd-iwt-content">
                                                                    <ProjectLocationIcons
                                                                        id="schools"
                                                                        defaultSrc="assets/animated/schools1.svg"
                                                                        hoverSrc="assets/animated/schools.svg"
                                                                        titleText="Schools"
                                                                        iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                                                    />

                                                                <ul>
                                                                    <li>Maharishi Vidya Mandir School </li>
                                                                    <li>Sri Chaitanya CBSE School</li>
                                                                    <li>Velammal Vidyalaya</li>
                                                                    <li>Jaigopal Garodia School</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                            <div className="mkd-iwt-content">
                                                                <ProjectLocationIcons
                                                                    id="colleges"
                                                                    defaultSrc="assets/animated/colleges.svg"
                                                                    hoverSrc="assets/animated/COLLEGES1.svg"
                                                                    titleText="Colleges"
                                                                    iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                                                />
                                                                <ul>
                                                                    <li>Panimalar Medical College</li>
                                                                    <li>ACS Medical College and Hospital</li>
                                                                    <li>Sindhi Arts and Science College </li>
                                                                    <li>SA Engineering College </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-iwt clearfix  mkd-iwt-icon-left mkd-iwt-icon-medium">
                                                            <div className="mkd-iwt-content">
                                                                <ProjectLocationIcons
                                                                    id="shopping"
                                                                    defaultSrc="assets/animated/shopping1.svg"
                                                                    hoverSrc="assets/animated/SHOPPING.svg"
                                                                    titleText="Shopping"
                                                                    iconStyle={{ width: 80, height: 80, marginBottom: '-20px' }}
                                                                />
                                                                <ul>
                                                                    <li>Apollo Speciality Hospital, Vanagaram </li>
                                                                    <li>Aravind Eye Hospital</li>
                                                                    <li>Sir Ivan Stedeford Hospitals </li>
                                                                    <li>Sri Ramachandra Medical College </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_empty_space"
                                                            style={{ height: 38 }}
                                                        >
                                                            <span className="vc_empty_space_inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div
                data-parallax-bg-image="assets/ongoing/clover-walkthrough.jpg"
                data-parallax-bg-speed={1}
                className="vc_row wpb_row vc_row-fluid vc_custom_1518019336900 vc_row-has-fill mkd-parallax-row-holder mkd-content-aligment-center"
            >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                <div
                                    className="mkd-eh-item    "
                                    data-item-class="mkd-eh-custom-9497"
                                    data-1280-1600="12.3% 0 15.4%"
                                    data-1024-1280="12.3% 0 15.4%"
                                    data-768-1024="11.3% 0 15.4%"
                                    data-680-768="10.3% 0 15.4%"
                                    data-680="10.3% 0 15.4%"
                                >
                                    <div className="mkd-eh-item-inner">
                                        <div
                                            className="mkd-eh-item-content mkd-eh-custom-9497"
                                            style={{ padding: "10.2% 0 12.3%" }}
                                        >
                                            <h1
                                                className="mkd-custom-font-holder  mkd-cf-6143  "
                                                style={{ color: "#ffffff" }}
                                                data-item-class="mkd-cf-6143"
                                            >
                                                Walkthrough
                                            </h1>
                                            <div className="mkd-video-button-holder  ">
                                                <div className="mkd-video-button-image"></div>
                                                <a
                                                    className="mkd-video-button-play"
                                                    style={{ color: "#ffffff", fontSize: 50 }}
                                                    href="https://www.youtube.com/watch?v=BA7uIKUfpno"
                                                    target="_self"
                                                    data-rel="prettyPhoto[video_button_pretty_photo_984]"
                                                >
                                                    <span className="mkd-video-button-play-inner">
                                                        <span className="arrow_triangle-right_alt" />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mkd-container mkd-default-page-template">
                <div className="mkd-container-inner clearfix">
                    <div className="mkd-grid-row">
                        <div className="mkd-page-content-holder mkd-grid-col-12">
                            <br />
                            <br />
                            <br />
                            <br />
                            <section className="wpb-content-wrapper">
                                <div className="mkd-row-grid-section-wrapper ">
                                    <div className="mkd-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1516007647796">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                            <div
                                                                className="mkd-eh-item    "
                                                                data-item-class="mkd-eh-custom-8773"
                                                                data-1280-1600="0 0 20px 0"
                                                                data-1024-1280="0 0 20px 0"
                                                                data-768-1024="0 0 20px 0"
                                                                data-680-768="0 0 20px 0"
                                                                data-680="0px"
                                                            >
                                                                <div className="mkd-eh-item-inner">
                                                                    <div
                                                                        className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                        style={{ padding: "0 0 20px 0" }}
                                                                    >
                                                                        <div className="mkd-section-title-holder  ">
                                                                            <div className="mkd-st-inner">
                                                                                <h2 className="mkd-st-title">
                                                                                    Frequently Asked Questions
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="wpb-content-wrapper">
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                                    <div className="wpb_column vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_empty_space" style={{ height: 13 }}>
                                                    <span className="vc_empty_space_inner" />
                                                </div>
                                                <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">
                                                            What is the starting price of Newry Clover County?
                                                        </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>
                                                                        The starting price of Newry Clover County is
                                                                        INR 28L* onwards.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">
                                                            What is the overall area of the Newry Clover County?
                                                        </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>
                                                                        The total area size of Newry Clover County is
                                                                        38 Acres
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 id="form-landing" className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">
                                                            Where is Newry Clover County located?
                                                        </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>
                                                                        Newry Clover County is located at Parivakkam,
                                                                        Poonamallee - Avadi Hoad, Chennai, Tamil Nadu
                                                                        600056
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="mkd-accordion-title">
                                                        <span className="mkd-accordion-mark">
                                                            <span className="mkd_icon_plus icon_plus" />
                                                            <span className="mkd_icon_minus icon_minus-06" />
                                                        </span>
                                                        <span className="mkd-tab-title">
                                                            What are the key amenities at Newry Clover County?
                                                        </span>
                                                    </h6>
                                                    <div className="mkd-accordion-content">
                                                        <div className="mkd-accordion-content-inner">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>
                                                                        The amenities at Newry Clover County include
                                                                        24/7 security, Green Landscaped Park
                                                                        Basketball Court, Gazeb, Children's Park and
                                                                        more.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="">
                <br />
                <br />
                <div className="mkd-content-inner">
                    <div className="mkd-full-width">
                        <div className="mkd-full-width-inner">
                            <div className="mkd-grid-row">
                                <div className="mkd-page-content-holder mkd-grid-col-12">
                                    <section className="wpb-content-wrapper">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-google-map-holder">
                                                            <input
                                                                type="hidden"
                                                                className="mkd-snazzy-map"
                                                                defaultValue=""
                                                            />
                                                            <div className="mkd-google-map-overlay" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div id="enquire-now" class="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768" style="background-color:#ebebeb; background-image: url(https://entre.qodeinteractive.com/wp-content/uploads/2018/01/contact-background-img.jpg)">*/}
                                        <div
                                            id="enquire-now"
                                            className="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768"
                                            style={{ backgroundColor: "#ebebeb" }}
                                        >
                                            <div className="mkd-row-grid-section">
                                                <div className="vc_row wpb_row vc_row-fluid">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-xs-12">
                                                                        <div className="vc_column-inner">
                                                                            <div className="wpb_wrapper">
                                                                                <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                                    <div
                                                                                        className="mkd-eh-item    mkd-element-from-bottom"
                                                                                        data-item-class="mkd-eh-custom-8629"
                                                                                        data-animation="mkd-element-from-bottom"
                                                                                        data-1280-1600="60px 0 0 8px"
                                                                                        data-1024-1280="60px 0 0 8px"
                                                                                        data-768-1024="60px 0 0 8px"
                                                                                        data-680-768="60px 0 0 8px"
                                                                                        data-680="60px 0 0 0"
                                                                                    >
                                                                                        <div className="mkd-eh-item-inner">
                                                                                            <div
                                                                                                className="mkd-eh-item-content mkd-eh-custom-8629"
                                                                                                style={{
                                                                                                    padding:
                                                                                                        "60px 0 0 8px !important"
                                                                                                }}
                                                                                            >
                                                                                                <div className="wpb_text_column wpb_content_element  vc_custom_1516695756662">
                                                                                                    <div className="wpb_wrapper">
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                fontSize: 25,
                                                                                                                paddingBottom: 6,
                                                                                                                fontFamily: "metropolis"
                                                                                                            }}
                                                                                                        >
                                                                                                            ENQUIRE NOW
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="vc_empty_space"
                                                                                                    style={{ height: 23 }}
                                                                                                >
                                                                                                    <span className="vc_empty_space_inner" />
                                                                                                </div>
                                                                                                <div className="wpb_text_column wpb_content_element ">
                                                                                                    <div className="wpb_wrapper">
                                                                                                        <p>
                                                                                                            Wish to get a call back from
                                                                                                            our team? Fill in your
                                                                                                            details.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="wpb_text_column wpb_content_element ">
                                                                                                    <div className="wpb_wrapper">
                                                                                                        <p>
                                                                                                            For bookings and more
                                                                                                            information, <br />
                                                                                                            <br />
                                                                                                            <a href="tel:9994144999">
                                                                                                                <span className="fa fa-phone" />{" "}
                                                                                                                <strong>
                                                                                                                    +91 999 4144 999
                                                                                                                </strong>
                                                                                                            </a>
                                                                                                            <br />
                                                                                                            <br />
                                                                                                            <a href="mailto:info@newry.co.in">
                                                                                                                <span className="fa fa-envelope" />{" "}
                                                                                                                <strong>
                                                                                                                    info@newry.co.in
                                                                                                                </strong>
                                                                                                            </a>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="vc_empty_space"
                                                                                                    style={{ height: 28 }}
                                                                                                >
                                                                                                    <span className="vc_empty_space_inner" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <EnquiryForm  clickedButton={clickedButton} />
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

};

export default CloverCounty;