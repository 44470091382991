import React, { useState } from 'react';
import CareerForm from "../modules/CareerForm";

const Careers = () => {

    const [formText, setFormText] = useState('');

    const handleButtonClick = (text) => {
        setFormText(text);
    };

    return <>
        <link rel="stylesheet" href="css/Careers.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/careers/banner.jpg" />
                            <source
                                media="(max-width: 480px)"
                                srcSet="assets/careers/banner-mobile.jpg"
                            />
                            <img
                                className="d-block w-100"
                                src="assets/careers/banner-mobile.jpg"
                                alt="Banner"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 60 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-container-inner clearfix">
                <div className="mkd-grid-row">
                    <div className="mkd-page-content-holder mkd-grid-col-12">
                        <section className="wpb-content-wrapper location-advantages">
                            <div className="mkd-row-grid-section-wrapper ">
                                <div className="mkd-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner vc_custom_1516007647796">
                                                <div className="wpb_wrapper">
                                                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                        <div className="mkd-eh-item    " data-item-class="mkd-eh-custom-8773" data-1280-1600="0 0 20px 0" data-1024-1280="0 0 20px 0" data-768-1024="0 0 20px 0" data-680-768="0 0 20px 0" data-680="0px">
                                                            <div className="mkd-eh-item-inner">
                                                                <div className="mkd-eh-item-content mkd-eh-custom-8773" style={{ padding: '0 0 20px 0' }}>
                                                                    <div className="mkd-section-title-holder  ">
                                                                        <div className="mkd-st-inner road">
                                                                            <h2 className="mkd-st-title">
                                                                                Positions Open
                                                                            </h2>
                                                                            <div className="vc_empty_space" style={{ height: '20px' }}>
                                                                                <span className="vc_empty_space_inner" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="vc_empty_space" style={{ height: '30px' }}>
                            <span className="vc_empty_space_inner" />
                        </div>
                        <section className="wpb-content-wrapper">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
                                <div className="wpb_column vc_col-sm-12">
                                    <div className="vc_column-inner">
                                        <div className="wpb_wrapper">
                                            <div className="vc_empty_space" style={{ height: '13px' }}><span className="vc_empty_space_inner" /></div>
                                            <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                                                <h6 className="mkd-accordion-title">
                                                    <span className="mkd-accordion-mark">
                                                        <span className="mkd_icon_plus icon_plus" />
                                                        <span className="mkd_icon_minus icon_minus-06" />
                                                    </span>
                                                    <span className="mkd-tab-title career-tile">MEP ENGINEER</span>
                                                    <a onClick={() => handleButtonClick('Marketing Manager')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                                                        <span className="mkd-btn-text">Apply Now</span>
                                                    </a>
                                                </h6>
                                                <div className="mkd-accordion-content">
                                                    <div className="mkd-accordion-content-inner">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <br />
                                                                <h5>Position Summary </h5>  <br />
                                                                <p>The MEP Engineer from a Real Estate or construction background will be responsible for all engineering and technical discipline that projects involve. 
                                                                   The position will also be majorly responsible to create detailed plans, conduct, calculate, select appropriate equipment and materials.
                                                                   Coordinate with various stakeholders throughout the construction process.
                                                                </p>
                                                                <br />
                                                                <h5>Duties and Responsibilities</h5> <br />
                                                                <ul>
                                                                    <li>To prepare, schedule, coordinate and monitor the assigned engineering projects.</li>
                                                                    <li>Review engineering deliverables and initiate appropriate corrective plans.</li>
                                                                    <li>To prepare cost estimates for electrical, plumbing, air conditioning, heating and ventilation systems.</li>
                                                                    <li>To coordinate architects, designers, consultants, purchase and admin personnel technical teams, contractors, vendors and supervisors.</li>
                                                                    <li>Review of drawings, estimates, calculations for covered and non-covered items MEP for live projects.</li>
                                                                    <li>To coordinate with authorities having jurisdiction AHJs for approvals.</li>
                                                                    <li>To manage and execute Mechanical, Electrical and plumbing engineering works.</li>
                                                                    <li>Ensure a strong reporting system as per requirements of senior management.</li>
                                                                    <li>Ensure resource availability and allocation.</li>
                                                                    <li>Create and maintain a comprehensive project documentation.</li>
                                                                    <li>Measure project performance using appropriate project management tools.</li>
                                                                    <li>Review of Firefighting submittals i.e., DBR, schematic drawings, Pumproom GA drawings, GFC
                                                                        drawings, BOQs, Technical specifications, approved makes, Equipment data sheets.</li>
                                                                    <li>STP & WTP Equipment Selection. Pumps And Valve Selection and TDS reviewing and approving.</li>
                                                                    <li>Actively engage with stakeholders to sort out construction related issues.</li>
                                                                    <li>Maintain an on-going list of services defects for the contractors remedial action.</li>
                                                                    <li>Coordination with other discipline & vendor related works so as not to have any clash or mistakes or rework.</li>
                                                                    <li>MEP works co-ordination & providing clearances for other agencies involved with interior sit out design.</li>
                                                                    <li>Ensure work is in accordance with working drawings & Specification. </li>
                                                                </ul>
                                                                <br />
                                                                <h5>Qualifications</h5> <br />
                                                                <ul>
                                                                    <li>BE – Civil Engineering/ B.tech. or equivalent.</li>
                                                                    <li>Diploma in Civil Engineering or equivalent.</li>
                                                                    <li>Knowledge in MEP design and structural Proven experience as a project engineer.</li>
                                                                    <li>PMP certification is an added advantage</li>
                                                                    <li>Excellent communication skills.</li>
                                                                    <li>Skilled in business support systems.</li>
                                                                    <li>Time management and organization skills.</li>
                                                                    <li>Project management skills.</li>
                                                                    
                                                                </ul>
                                                                <br />
                                                                <h5>Person profile</h5>
                                                                <ul>
                                                                    <li>Self-driven, energetic, and ambitious.</li>
                                                                    <li>Fast learner and passion for sales.</li>
                                                                    <li>Strong analytical skills and attention to details.</li>
                                                                    <li>Good combination of business skills.</li>
                                                                    <li>Good understanding of interpersonal relationships.</li>
                                                                    <li>Efficient and able to make good and fast decisions.</li>
                                                                    <li>Quality conscious and reliable.</li>
                                                                    <li>Strong written and oral communication skills.</li>
                                                                    <li>Ability to build strong relationships internally and externally.</li>
                                                                    <li>High integrity.</li>
                                                                    <li>Time management skills.</li>
                                                                    <li>Team player.</li>
                                                                    <li>Ability to work with multi discipline projects.</li>
                                                                </ul>

                                                                <br />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <h6 className="mkd-accordion-title">
                                                    <span className="mkd-accordion-mark">
                                                        <span className="mkd_icon_plus icon_plus" />
                                                        <span className="mkd_icon_minus icon_minus-06" />
                                                    </span>
                                                    <span className="mkd-tab-title career-tile">Multi Skill Technician</span>
                                                    <a onClick={() => handleButtonClick('Multi Skill Technician')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                                                        <span className="mkd-btn-text">Apply Now</span>
                                                    </a>
                                                </h6> */}
                                                {/* <div className="mkd-accordion-content">
                                                    <div className="mkd-accordion-content-inner">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <br />
                                                                <h5>Position Summary </h5>  <br />
                                                                <p>The Multi skilled technician from a construction background will be responsible to manage and provide a
                                                                    comprehensive technical service to required project sites to provide high quality and responsive multi
                                                                    skilled maintenance repairs and replacement services.
                                                                </p>

                                                                <br />
                                                                <h5>Duties and Responsibilities</h5> <br />
                                                                <ul>
                                                                    <li>To carry out planned and responsive repairs and installations as required in construction sites and
                                                                        refurbishments activity in completed projects.</li>
                                                                    <li>Responds to repair and maintenance requests in timely fashion.</li>
                                                                    <li>To contribute to identifying and undertaking initiatives aimed at improving the efficiency and
                                                                        effectiveness of service delivery.</li>
                                                                    <li>To inspect and maintain building systems as per regulations requirement.</li>
                                                                    <li>Contribute to the development of maintenance budget and ensure compliance.</li>
                                                                    <li>Use equipment's including power tools, hand tools and plumbing tools.</li>
                                                                    <li>Read and follow manuals, blueprints, and other written instructions.</li>
                                                                    <li>Collaborate with maintenance team and sub-contractors as required.</li>
                                                                    <li>Maintain, service, clean and properly store equipment's.</li>
                                                                    <li>Regularly check functionality of safety, electrical, plumbing systems.</li>
                                                                    <li>Participate in ensuring maintenance of electrical, plumbing, safety and HVAC systems in multiple
                                                                        construction sites and projects under maintenance.</li>
                                                                    <li>Any other works as assigned/specified by the management from time to time.</li>
                                                                </ul>

                                                                <br />
                                                                <h5>Qualifications</h5> <br />
                                                                <ul>
                                                                    <li>Diploma in (ITI) or equivalent.</li>
                                                                    <li>Trade Qualification will be an added advantage.</li>
                                                                    <li>Certification in technical skill or equivalent.</li>
                                                                    <li>Proven experience as Multi skilled Technician.</li>
                                                                    <li>Good communication skills.</li>
                                                                    <li>Knowledge of HVAC, plumbing and electrical systems.</li>
                                                                    <li>Knowledge on construction site activities.</li>
                                                                </ul>
                                                                <br />
                                                                <h5>Person profile</h5>
                                                                <ul>
                                                                    <li>Self-driven, energetic, and ambitious</li>
                                                                    <li>Fast learner and passion for construction.</li>
                                                                    <li>Strong analytical skills and attention to details.</li>
                                                                    <li>Good combination of technical skills.</li>
                                                                    <li>Good understanding of interpersonal relationships.</li>
                                                                    <li>Efficient and able to make good and fast decisions.</li>
                                                                    <li>Quality conscious and reliable.</li>
                                                                    <li>Strong written and oral communication skills.</li>
                                                                    <li>Ability to build strong relationships internally and externally.</li>
                                                                    <li>High integrity.</li>
                                                                    <li>Ability to work with multi discipline projects.</li>
                                                                </ul>

                                                                <br />
                                                                <h5>Reporting</h5> <br />
                                                                <p>The position reports to the General Manager – Projects.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br />
                        <br />
                    </div>
                </div>
            </div>

            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner road">
                                                                                    <h2 className="mkd-st-title">
                                                                                        Our Culture
                                                                                    </h2>
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 40 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/*VISION START*/}
                                <div className="mkd-full-width">
                                    <div className="mkd-full-width-inner">
                                        <div className="mkd-grid-row">
                                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                                <section className="wpb-content-wrapper">
                                                    <div className="mkd-row-grid-section-wrapper ">
                                                        <div className="mkd-row-grid-section">
                                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921277402">
                                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-portfolio-list-holder mkd-pl-gallery mkd-huge-space mkd-pl-one-column mkd-pl-floating-portfolio-item  mkd-pl-pag-no-pagination ">
                                                                                <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/freepik/ca2.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <p style={{ textAlign: "justify" }}
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            At our company, we foster a
                                                                                                            people-centric environment
                                                                                                            where continuous learning and
                                                                                                            adaptation to technological
                                                                                                            advancements are encouraged.
                                                                                                            Our performance-driven culture
                                                                                                            thrives on collaboration and
                                                                                                            support, ensuring equal
                                                                                                            opportunities for individuals
                                                                                                            from diverse backgrounds. We
                                                                                                            actively promote gender
                                                                                                            diversity and inclusion,
                                                                                                            creating a workplace where
                                                                                                            everyone can excel.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/freepik/ca1.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <p style={{ textAlign: "justify" }}
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            We believe in engaging our
                                                                                                            employees through various
                                                                                                            initiatives and maintaining a
                                                                                                            positive work environment. Our
                                                                                                            commitment to social and
                                                                                                            sustainable practices extends
                                                                                                            beyond our office walls. We
                                                                                                            prioritize corporate social
                                                                                                            responsibility and
                                                                                                            sustainability, engaging in
                                                                                                            community service and
                                                                                                            environmentally conscious
                                                                                                            operations.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/freepik/ca3.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <p style={{ textAlign: "justify" }}
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            Ethics and transparency are
                                                                                                            the cornerstone of our
                                                                                                            business. We uphold ethical
                                                                                                            practices and transparency in
                                                                                                            all our dealings. Our
                                                                                                            customer-centric approach
                                                                                                            prioritizes satisfaction and
                                                                                                            service excellence, with a
                                                                                                            focus on building lasting
                                                                                                            relationships. Clear
                                                                                                            communication with clients and
                                                                                                            stakeholders is integral to
                                                                                                            our success.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*VISION END*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
        data-parallax-bg-image="assets/altura/altura_background.png"
        data-parallax-bg-speed={1}
        className="vc_row wpb_row vc_row-fluid vc_custom_1518019336900 vc_row-has-fill mkd-parallax-row-holder mkd-content-aligment-center"
      >
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                <div
                  className="mkd-eh-item    "
                  data-item-class="mkd-eh-custom-9497"
                  data-1280-1600="12.3% 0 15.4%"
                  data-1024-1280="12.3% 0 15.4%"
                  data-768-1024="11.3% 0 15.4%"
                  data-680-768="10.3% 0 15.4%"
                  data-680="10.3% 0 15.4%"
                >
                  <div className="mkd-eh-item-inner">
                    <div
                      className="mkd-eh-item-content mkd-eh-custom-9497"
                      style={{ padding: "10.2% 0 12.3%" }}
                    >
                      <h1
                        className="mkd-custom-font-holder  mkd-cf-6143  "
                        style={{ color: "#ffffff" }}
                        data-item-class="mkd-cf-6143"
                      >
                        Our Culture
                      </h1>
                      <div className="mkd-video-button-holder  ">
                        <div className="mkd-video-button-image"></div>
                        <a
                          className="mkd-video-button-play"
                          style={{ color: "#ffffff", fontSize: 50 }}
                          href="https://youtu.be/MknRrRI8BGQ"
                          target="_self"
                          data-rel="prettyPhoto[video_button_pretty_photo_984]"
                        >
                          <span className="mkd-video-button-play-inner">
                            <span className="arrow_triangle-right_alt" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


            <div className="mkd-container mkd-default-page-template">
                <div className="mkd-container-inner clearfix">
                    <div className="mkd-grid-row">
                        <div className="mkd-page-content-holder mkd-grid-col-12">
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <div className="mkd-content-inner">
                    <div className="mkd-full-width">
                        <div className="mkd-full-width-inner">
                            <div className="mkd-grid-row">
                                <div className="mkd-page-content-holder mkd-grid-col-12">
                                    <section className="wpb-content-wrapper">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-google-map-holder">
                                                            <input
                                                                type="hidden"
                                                                className="mkd-snazzy-map"
                                                                defaultValue=""
                                                            />
                                                            <div className="mkd-google-map-overlay" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="enquire-now"
                                            className="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768"
                                            style={{ backgroundColor: "#ebebeb" }}
                                        >
                                            <div className="mkd-row-grid-section">
                                                <div className="vc_row wpb_row vc_row-fluid">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div
                                                                className="vc_empty_space"
                                                                style={{ height: 35 }}
                                                            >
                                                                <span className="vc_empty_space_inner" />
                                                            </div>
                                                            <div className="wpb_wrapper">
                                                                <h6
                                                                    id="form-phone-container"
                                                                    style={{
                                                                        fontSize: 25,
                                                                        paddingBottom: 6,
                                                                        fontFamily: "metropolis"
                                                                    }}
                                                                >
                                                                    POST YOUR RESUME
                                                                </h6>
                                                                <p>
                                                                    Join us if you thrive in a structured,
                                                                    process-driven culture that respects authority and
                                                                    values adherence to established procedures. We
                                                                    offer a dynamic environment where your skills and
                                                                    talents can flourish, contributing to our
                                                                    collective success. Connect with us
                                                                    <br></br>
                                                                    <a href="mailto:talents.hr@newry.co.in">

                                                                        talents.hr@newry.co.in
                                                                    </a>
                                                                </p>
                                                            </div>
                                                            <div id="form-pointer"></div>
                                                            <CareerForm formText={formText} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Careers;